"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionStatus = void 0;
var AuctionStatus;
(function (AuctionStatus) {
    AuctionStatus[AuctionStatus["CREATED"] = 0] = "CREATED";
    AuctionStatus[AuctionStatus["STARTED"] = 1] = "STARTED";
    AuctionStatus[AuctionStatus["COMPLETE"] = 2] = "COMPLETE";
    AuctionStatus[AuctionStatus["CANCELLED"] = 3] = "CANCELLED";
    AuctionStatus[AuctionStatus["EXPIRED"] = 4] = "EXPIRED";
})(AuctionStatus = exports.AuctionStatus || (exports.AuctionStatus = {}));
