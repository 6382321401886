"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethodType = exports.PaymentCurrencyType = void 0;
var PaymentCurrencyType;
(function (PaymentCurrencyType) {
    PaymentCurrencyType["USD"] = "usd";
})(PaymentCurrencyType = exports.PaymentCurrencyType || (exports.PaymentCurrencyType = {}));
var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["CARD"] = "card";
})(PaymentMethodType = exports.PaymentMethodType || (exports.PaymentMethodType = {}));
