"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchStatsMintById = exports.fetchStatsById = void 0;
async function fetchStatsById(axiosInstance, storeId) {
    console.log('CandyShop: fetching Stats');
    return axiosInstance.get(`/stats/${storeId}`).then((response) => response.data.result);
}
exports.fetchStatsById = fetchStatsById;
async function fetchStatsMintById(axiosInstance, storeId, mint) {
    return await axiosInstance
        .get(`/stats/${storeId}/${mint}`)
        .then((response) => response.data.result);
}
exports.fetchStatsMintById = fetchStatsMintById;
