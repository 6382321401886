"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteCandyShopIDB = exports.retrieveWalletNftFromIDB = exports.storeWalletNftToIDB = void 0;
/**
 * A helper to handle idb library to store/retrieve data from IndexedDB in simplest way.
 */
const idb_1 = require("idb");
const vendor_1 = require("../vendor");
const idb_model_1 = require("./idb-model");
const Logger = 'CandyShopSDK/idb-handler';
const CANDY_SHOP_NFT_IDB_NAME = 'candyshop-nft';
async function storeWalletNftToIDB(walletAddress, tokens) {
    const db = await openCandyShopIDB();
    const store = await (0, vendor_1.safeAwait)(db.put(idb_model_1.USER_NFT_OBJECT_STORE, tokens, walletAddress));
    if (store.error) {
        console.log(`${Logger}: Store walletAddress=${walletAddress} tokens failed, error=${store.error}`);
        return false;
    }
    console.log(`${Logger}: Store walletAddress=${walletAddress} tokens success`);
    return true;
}
exports.storeWalletNftToIDB = storeWalletNftToIDB;
async function retrieveWalletNftFromIDB(walletAddress) {
    const db = await openCandyShopIDB();
    const retrieve = await (0, vendor_1.safeAwait)(db.get(idb_model_1.USER_NFT_OBJECT_STORE, walletAddress));
    if (retrieve.error) {
        console.log(`${Logger}: Retrieve walletAddress=${walletAddress} tokens failed, error=${retrieve.error}`);
        return undefined;
    }
    return retrieve.result;
}
exports.retrieveWalletNftFromIDB = retrieveWalletNftFromIDB;
async function deleteCandyShopIDB() {
    return await (0, idb_1.deleteDB)(CANDY_SHOP_NFT_IDB_NAME, {
        blocked() {
            console.log(`${Logger}: Can not delete ${CANDY_SHOP_NFT_IDB_NAME} in IndexedDB, there's connection still opening it`);
        }
    });
}
exports.deleteCandyShopIDB = deleteCandyShopIDB;
// TODO: We will need to take care the version once have more than one objectStore in IDB
function openCandyShopIDB(version) {
    return (0, idb_1.openDB)(CANDY_SHOP_NFT_IDB_NAME, version, {
        upgrade: (db, oldVersion, newVersion) => {
            console.log(`${Logger}: upgrading IDB ${db.name} from ${oldVersion} to ${newVersion}`);
            db.createObjectStore(idb_model_1.USER_NFT_OBJECT_STORE);
        }
    });
}
