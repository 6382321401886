"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redeemNft = void 0;
const spl_token_1 = require("@solana/spl-token");
const web3_js_1 = require("@solana/web3.js");
const vendor_1 = require("../../../../vendor");
const redeemNft = async (params) => {
    const { nftOwner, vaultAccount, nftOwnerTokenAccount, masterMint, program } = params;
    await (0, vendor_1.checkRedeemable)(vaultAccount, program);
    const transaction = new web3_js_1.Transaction();
    const [vaultTokenAccount] = await (0, vendor_1.getAtaForMint)(masterMint, vaultAccount);
    const ix = await program.methods
        .redeemNft()
        .accounts({
        nftOwner: nftOwner.publicKey,
        nftOwnerTokenAccount,
        vaultAccount,
        vaultTokenAccount,
        nftMint: masterMint,
        systemProgram: web3_js_1.SystemProgram.programId,
        tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
        rent: web3_js_1.SYSVAR_RENT_PUBKEY
    })
        .instruction();
    transaction.add(ix);
    return await (0, vendor_1.sendTx)(nftOwner, transaction, program);
};
exports.redeemNft = redeemNft;
