"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.settleAndDistributeProceeds = void 0;
const anchor = __importStar(require("@project-serum/anchor"));
const spl_token_1 = require("@solana/spl-token");
const web3_js_1 = require("@solana/web3.js");
const vendor_1 = require("../../../../vendor");
const requestExtraComputeIx_1 = require("./requestExtraComputeIx");
const settleAndDistributeProceeds = async ({ settler, metadata, auctionHouse, treasuryAccount, feeAccount, candyShop, treasuryMint, authority, auction, auctionBump, nftMint, program, env }) => {
    await (0, vendor_1.checkAHFeeAccountBalance)(feeAccount, program.provider.connection);
    const isNative = (0, vendor_1.treasuryMintIsNative)(treasuryMint);
    await (0, vendor_1.checkSettleParams)(auction, program);
    const auctionData = await (0, vendor_1.getAuctionData)(auction, program);
    const bid = auctionData.highestBid.key;
    const bidPrice = auctionData.highestBid.price;
    const seller = auctionData.seller;
    const bidData = await (0, vendor_1.getBidData)(bid, program);
    const buyer = bidData.buyer;
    const [[auctionEscrow], [bidWallet, bidWalletBump], [programAsSigner, programAsSignerBump], [buyerReceiptTokenAccount]] = await Promise.all([
        (0, vendor_1.getAtaForMint)(nftMint, auction),
        (0, vendor_1.getBidWallet)(auction, buyer, program.programId),
        (0, vendor_1.getAuctionHouseProgramAsSigner)(),
        (0, vendor_1.getAtaForMint)(nftMint, buyer)
    ]);
    const [[escrowPaymentAccount, escrowPaymentAccountBump], [bidReceiptTokenAccount], [auctionTradeState, auctionTradeStateBump], [freeAuctionTradeState, freeAuctionTradeStateBump], [bidTradeState]] = await Promise.all([
        (0, vendor_1.getAuctionHouseEscrow)(auctionHouse, bidWallet),
        (0, vendor_1.getAtaForMint)(nftMint, bidWallet),
        (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, auction, auctionEscrow, treasuryMint, nftMint, new anchor.BN(1), bidPrice),
        await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, auction, auctionEscrow, treasuryMint, nftMint, new anchor.BN(1), new anchor.BN(0)),
        (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, bidWallet, auctionEscrow, treasuryMint, nftMint, new anchor.BN(1), bidPrice)
    ]);
    const auctionPaymentReceiptAccount = isNative ? auction : (await (0, vendor_1.getAtaForMint)(treasuryMint, auction))[0];
    const sellerPaymentReceiptAccount = isNative ? seller : (await (0, vendor_1.getAtaForMint)(treasuryMint, seller))[0];
    const remainingAccounts = await (0, vendor_1.getRemainingAccountsForExecuteSaleIx)(metadata, program.provider.connection, treasuryMint, isNative);
    const ix1 = await program.methods
        .settleAuction(auctionBump, bidWalletBump, auctionTradeStateBump, freeAuctionTradeStateBump, escrowPaymentAccountBump, programAsSignerBump)
        .accounts({
        auction,
        auctionEscrow,
        auctionPaymentReceiptAccount,
        bidReceiptTokenAccount,
        wallet: settler.publicKey,
        bid,
        auctionBidWallet: bidWallet,
        buyer,
        escrowPaymentAccount,
        auctionHouse,
        auctionHouseFeeAccount: feeAccount,
        auctionHouseTreasury: treasuryAccount,
        nftMint,
        treasuryMint,
        metadata,
        candyShop,
        authority,
        bidTradeState,
        auctionTradeState,
        freeAuctionTradeState,
        ahProgram: vendor_1.AUCTION_HOUSE_PROGRAM_ID,
        programAsSigner,
        ataProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
        clock: web3_js_1.SYSVAR_CLOCK_PUBKEY
    })
        .remainingAccounts(remainingAccounts)
        .instruction();
    let transaction = new web3_js_1.Transaction();
    transaction.add((0, requestExtraComputeIx_1.requestExtraComputeIx)(1400000));
    transaction.add(ix1);
    const tx1 = await (0, vendor_1.sendTx)(settler, transaction, program);
    console.log('Auction settled with txId ==', tx1);
    const ix2 = await program.methods
        .distributeAuctionProceeds(auctionBump, bidWalletBump)
        .accounts({
        auction,
        auctionPaymentReceiptAccount,
        bidReceiptTokenAccount,
        sellerPaymentReceiptAccount,
        buyerReceiptTokenAccount,
        wallet: settler.publicKey,
        bid,
        auctionBidWallet: bidWallet,
        buyer,
        seller,
        nftMint,
        treasuryMint,
        candyShop,
        ataProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
        clock: web3_js_1.SYSVAR_CLOCK_PUBKEY
    })
        .instruction();
    transaction = new web3_js_1.Transaction();
    transaction.add(ix2);
    const tx2 = await (0, vendor_1.sendTx)(settler, transaction, program);
    console.log('Proceeds distributed with txId ==', tx2);
    return tx2;
};
exports.settleAndDistributeProceeds = settleAndDistributeProceeds;
