"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExplorerLinkBase = exports.CandyShopVersion = void 0;
/**
 * Program version that CandyShop was created with
 */
var CandyShopVersion;
(function (CandyShopVersion) {
    CandyShopVersion[CandyShopVersion["V1"] = 0] = "V1";
    CandyShopVersion[CandyShopVersion["V2"] = 1] = "V2";
})(CandyShopVersion = exports.CandyShopVersion || (exports.CandyShopVersion = {}));
/**
 * Explorer link source on checking on-chain information
 */
var ExplorerLinkBase;
(function (ExplorerLinkBase) {
    ExplorerLinkBase["SolScan"] = "SolScan";
    ExplorerLinkBase["SolanaFM"] = "SolanaFM";
    ExplorerLinkBase["Explorer"] = "Explorer";
})(ExplorerLinkBase = exports.ExplorerLinkBase || (exports.ExplorerLinkBase = {}));
