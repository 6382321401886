import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { FieldContext } from '.';
import warning from "rc-util/es/warning";
import { HOOK_MARK } from './FieldContext';
import { useState, useContext, useEffect, useRef } from 'react';
import { getNamePath, getValue } from './utils/valueUtil';

function useWatch() {
  var dependencies = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var form = arguments.length > 1 ? arguments[1] : undefined;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var valueCacheRef = useRef();
  valueCacheRef.current = value;
  var fieldContext = useContext(FieldContext);
  var formInstance = form || fieldContext;
  var isValidForm = formInstance && formInstance._init; // Warning if not exist form instance

  if (process.env.NODE_ENV !== 'production') {
    warning(isValidForm, 'useWatch requires a form instance since it can not auto detect from context.');
  }

  var namePath = getNamePath(dependencies);
  var namePathRef = useRef(namePath);
  namePathRef.current = namePath;
  useEffect(function () {
    // Skip if not exist form instance
    if (!isValidForm) {
      return;
    }

    var getFieldsValue = formInstance.getFieldsValue,
        getInternalHooks = formInstance.getInternalHooks;

    var _getInternalHooks = getInternalHooks(HOOK_MARK),
        registerWatch = _getInternalHooks.registerWatch;

    var cancelRegister = registerWatch(function (store) {
      var newValue = getValue(store, namePathRef.current);

      if (valueCacheRef.current !== newValue) {
        setValue(newValue);
      }
    }); // TODO: We can improve this perf in future

    var initialValue = getValue(getFieldsValue(), namePathRef.current);
    setValue(initialValue);
    return cancelRegister;
  }, // We do not need re-register since namePath content is the same
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return value;
}

export default useWatch;