"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopStatusType = void 0;
var ShopStatusType;
(function (ShopStatusType) {
    ShopStatusType["Order"] = "ORDER";
    ShopStatusType["Trade"] = "TRADE";
    ShopStatusType["UserNft"] = "USER_NFT";
    ShopStatusType["Auction"] = "AUCTION";
})(ShopStatusType = exports.ShopStatusType || (exports.ShopStatusType = {}));
