"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseNftUpdateAuthority = exports.parseEdition = exports.parseEditionMarker = exports.parseMetadata = exports.parseMasterEditionV2 = exports.METADATA_SCHEMA = exports.Metadata = exports.Data = exports.Edition = exports.EditionMarker = exports.MasterEditionV2 = exports.MetadataKey = exports.Creator = exports.METADATA_REPLACE = void 0;
const borsh_1 = require("borsh");
const web3_js_1 = require("@solana/web3.js");
const utils_1 = require("../utils");
const error_1 = require("../error");
// eslint-disable-next-line
exports.METADATA_REPLACE = new RegExp('\u0000', 'g');
class Creator {
    constructor(args) {
        this.address = args.address;
        this.verified = args.verified;
        this.share = args.share;
    }
}
exports.Creator = Creator;
var MetadataKey;
(function (MetadataKey) {
    MetadataKey[MetadataKey["Uninitialized"] = 0] = "Uninitialized";
    MetadataKey[MetadataKey["MetadataV1"] = 4] = "MetadataV1";
    MetadataKey[MetadataKey["EditionV1"] = 1] = "EditionV1";
    MetadataKey[MetadataKey["MasterEditionV1"] = 2] = "MasterEditionV1";
    MetadataKey[MetadataKey["MasterEditionV2"] = 6] = "MasterEditionV2";
    MetadataKey[MetadataKey["EditionMarker"] = 7] = "EditionMarker";
})(MetadataKey = exports.MetadataKey || (exports.MetadataKey = {}));
class MasterEditionV2 {
    constructor(args) {
        this.key = MetadataKey.MasterEditionV2;
        this.supply = args.supply;
        this.maxSupply = args.maxSupply;
    }
}
exports.MasterEditionV2 = MasterEditionV2;
class EditionMarker {
    constructor(args) {
        this.key = MetadataKey.EditionMarker;
        this.ledger = args.ledger;
    }
}
exports.EditionMarker = EditionMarker;
class Edition {
    constructor(args) {
        this.key = MetadataKey.EditionV1;
        this.parent = args.parent;
        this.edition = args.edition;
    }
}
exports.Edition = Edition;
class Data {
    constructor(args) {
        this.name = args.name;
        this.symbol = args.symbol;
        this.uri = args.uri;
        this.sellerFeeBasisPoints = args.sellerFeeBasisPoints;
        this.creators = args.creators;
    }
}
exports.Data = Data;
class Metadata {
    constructor(args) {
        this.key = MetadataKey.MetadataV1;
        this.updateAuthority = args.updateAuthority;
        this.mint = args.mint;
        this.data = args.data;
        this.primarySaleHappened = args.primarySaleHappened;
        this.isMutable = args.isMutable;
    }
}
exports.Metadata = Metadata;
exports.METADATA_SCHEMA = new Map([
    [
        MasterEditionV2,
        {
            kind: 'struct',
            fields: [
                ['key', 'u8'],
                ['supply', 'u64'],
                ['maxSupply', { kind: 'option', type: 'u64' }]
            ]
        }
    ],
    [
        Edition,
        {
            kind: 'struct',
            fields: [
                ['key', 'u8'],
                ['parent', [32]],
                ['edition', 'u64']
            ]
        }
    ],
    [
        Data,
        {
            kind: 'struct',
            fields: [
                ['name', 'string'],
                ['symbol', 'string'],
                ['uri', 'string'],
                ['sellerFeeBasisPoints', 'u16'],
                ['creators', { kind: 'option', type: [Creator] }]
            ]
        }
    ],
    [
        Creator,
        {
            kind: 'struct',
            fields: [
                ['address', [32]],
                ['verified', 'u8'],
                ['share', 'u8']
            ]
        }
    ],
    [
        Metadata,
        {
            kind: 'struct',
            fields: [
                ['key', 'u8'],
                ['updateAuthority', [32]],
                ['mint', [32]],
                ['data', Data],
                ['primarySaleHappened', 'u8'],
                ['isMutable', 'u8']
            ]
        }
    ],
    [
        EditionMarker,
        {
            kind: 'struct',
            fields: [
                ['key', 'u8'],
                ['ledger', [31]]
            ]
        }
    ]
]);
const parseMasterEditionV2 = (buffer) => {
    return (0, borsh_1.deserializeUnchecked)(exports.METADATA_SCHEMA, MasterEditionV2, buffer);
};
exports.parseMasterEditionV2 = parseMasterEditionV2;
const parseMetadata = (buffer) => {
    const metadata = (0, borsh_1.deserializeUnchecked)(exports.METADATA_SCHEMA, Metadata, buffer);
    metadata.data.name = metadata.data.name.replace(exports.METADATA_REPLACE, '');
    metadata.data.uri = metadata.data.uri.replace(exports.METADATA_REPLACE, '');
    metadata.data.symbol = metadata.data.symbol.replace(exports.METADATA_REPLACE, '');
    return metadata;
};
exports.parseMetadata = parseMetadata;
const parseEditionMarker = (buffer) => {
    const editionMarker = (0, borsh_1.deserializeUnchecked)(exports.METADATA_SCHEMA, EditionMarker, buffer);
    return editionMarker;
};
exports.parseEditionMarker = parseEditionMarker;
const parseEdition = (buffer) => {
    return (0, borsh_1.deserializeUnchecked)(exports.METADATA_SCHEMA, Edition, buffer);
};
exports.parseEdition = parseEdition;
const parseNftUpdateAuthority = async (metadataAddress, connection) => {
    const metadataAccount = await (0, utils_1.safeAwait)(connection.getAccountInfo(metadataAddress));
    if (metadataAccount.error || !metadataAccount.result) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.NodeRequestFailed);
    }
    const metadata = (0, exports.parseMetadata)(metadataAccount.result.data);
    return new web3_js_1.PublicKey(metadata.updateAuthority);
};
exports.parseNftUpdateAuthority = parseNftUpdateAuthority;
