"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAuction = void 0;
const web3_js_1 = require("@solana/web3.js");
const vendor_1 = require("../../../../vendor");
const createAuction = async (params) => {
    const { seller, auction, auctionBump, candyShop, treasuryMint, nftMint, startingBid, startTime, biddingPeriod, tickSize, buyNowPrice, extensionPeriod, extensionIncrement, program } = params;
    (0, vendor_1.checkCreationParams)(startTime, startingBid, buyNowPrice, tickSize);
    await (0, vendor_1.checkCreators)(treasuryMint, nftMint, program.provider.connection, vendor_1.TransactionType.Auction);
    (0, vendor_1.checkExtensionParams)(extensionPeriod, extensionIncrement);
    const [[auctionEscrow], [tokenAccount], [authority]] = await Promise.all([
        (0, vendor_1.getAtaForMint)(nftMint, auction),
        (0, vendor_1.getAtaForMint)(nftMint, seller.publicKey),
        (0, vendor_1.getAuctionHouseAuthority)(seller.publicKey, treasuryMint, program.programId)
    ]);
    const transaction = new web3_js_1.Transaction();
    const ix = await program.methods
        .createAuction(startingBid, startTime, biddingPeriod, tickSize, buyNowPrice, extensionPeriod !== null && extensionPeriod !== void 0 ? extensionPeriod : null, extensionIncrement !== null && extensionIncrement !== void 0 ? extensionIncrement : null)
        .accounts({
        auction,
        auctionEscrow,
        tokenAccount,
        wallet: seller.publicKey,
        nftMint,
        candyShop,
        authority,
        clock: web3_js_1.SYSVAR_CLOCK_PUBKEY
    })
        .instruction();
    transaction.add(ix);
    const txId = await (0, vendor_1.sendTx)(seller, transaction, program);
    console.log('Auction created with txId ==', txId);
    return txId;
};
exports.createAuction = createAuction;
