"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CandyShopTrade = void 0;
const anchor_1 = require("@project-serum/anchor");
const program_1 = require("./factory/program");
const vendor_1 = require("./vendor");
const shipping_1 = require("./vendor/shipping");
/**
 * A abstract class to provide static trading methods without CandyShop instance but partial information from shop.
 */
class CandyShopTrade {
    static async buy(params) {
        const { connection, seller, tokenAccount, tokenMint, price, wallet, shopAddress, candyShopProgramId, shopTreasuryMint, shopCreatorAddress, isEnterprise } = params;
        const [auctionHouseAuthority, authorityBump] = await (0, vendor_1.getAuctionHouseAuthority)(shopCreatorAddress, shopTreasuryMint, candyShopProgramId);
        const [auctionHouse] = await (0, vendor_1.getAuctionHouse)(auctionHouseAuthority, shopTreasuryMint);
        const [feeAccount] = await (0, vendor_1.getAuctionHouseFeeAcct)(auctionHouse);
        const [treasuryAccount] = await (0, vendor_1.getAuctionHouseTreasuryAcct)(auctionHouse);
        const [metadata] = await (0, vendor_1.getMetadataAccount)(tokenMint);
        const buyTxHashParams = {
            wallet,
            counterParty: seller,
            tokenAccount,
            tokenAccountMint: tokenMint,
            treasuryMint: shopTreasuryMint,
            auctionHouseTreasury: treasuryAccount,
            metadata,
            authority: auctionHouseAuthority,
            authorityBump,
            auctionHouse,
            feeAccount,
            candyShop: shopAddress,
            price,
            amount: new anchor_1.BN(1),
            program: (0, vendor_1.getProgram)(connection, candyShopProgramId, wallet)
        };
        const txHash = await (0, shipping_1.proceedToBuy)(isEnterprise, {
            params: buyTxHashParams,
            version: (0, vendor_1.getCandyShopVersion)(candyShopProgramId),
            v1Func: program_1.buyAndExecuteSaleV1,
            v2Func: program_1.buyAndExecuteSale
        });
        return txHash;
    }
    static async sell(params) {
        const { connection, tokenAccount, tokenMint, price, wallet, shopAddress, candyShopProgramId, shopTreasuryMint, shopCreatorAddress } = params;
        const [auctionHouseAuthority, authorityBump] = await (0, vendor_1.getAuctionHouseAuthority)(shopCreatorAddress, shopTreasuryMint, candyShopProgramId);
        const [auctionHouse] = await (0, vendor_1.getAuctionHouse)(auctionHouseAuthority, shopTreasuryMint);
        const [feeAccount] = await (0, vendor_1.getAuctionHouseFeeAcct)(auctionHouse);
        const [metadata] = await (0, vendor_1.getMetadataAccount)(tokenMint);
        const sellTxParams = {
            wallet,
            tokenAccount,
            tokenAccountMint: tokenMint,
            treasuryMint: shopTreasuryMint,
            metadata,
            authority: auctionHouseAuthority,
            authorityBump,
            auctionHouse,
            feeAccount,
            candyShop: shopAddress,
            price,
            amount: new anchor_1.BN(1),
            program: (0, vendor_1.getProgram)(connection, candyShopProgramId, wallet)
        };
        const candyShopVersion = (0, vendor_1.getCandyShopVersion)(candyShopProgramId);
        const txHash = await (0, shipping_1.supply)(sellTxParams, candyShopVersion, program_1.sellNftV1, program_1.sellNft);
        return txHash;
    }
    static async cancel(params) {
        const { connection, tokenAccount, tokenMint, price, wallet, shopAddress, candyShopProgramId, shopTreasuryMint, shopCreatorAddress } = params;
        const [auctionHouseAuthority, authorityBump] = await (0, vendor_1.getAuctionHouseAuthority)(shopCreatorAddress, shopTreasuryMint, candyShopProgramId);
        const [auctionHouse] = await (0, vendor_1.getAuctionHouse)(auctionHouseAuthority, shopTreasuryMint);
        const [feeAccount] = await (0, vendor_1.getAuctionHouseFeeAcct)(auctionHouse);
        const [tradeState] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, wallet.publicKey, tokenAccount, shopTreasuryMint, tokenMint, new anchor_1.BN(1), price);
        const cancelTxParams = {
            wallet,
            tokenAccount,
            tokenAccountMint: tokenMint,
            treasuryMint: shopTreasuryMint,
            authority: auctionHouseAuthority,
            authorityBump,
            auctionHouse,
            feeAccount,
            tradeState,
            candyShop: shopAddress,
            price,
            amount: new anchor_1.BN(1),
            program: (0, vendor_1.getProgram)(connection, candyShopProgramId, wallet)
        };
        const candyShopVersion = (0, vendor_1.getCandyShopVersion)(candyShopProgramId);
        const txHash = await (0, shipping_1.supply)(cancelTxParams, candyShopVersion, program_1.cancelOrderV1, program_1.cancelOrder);
        return txHash;
    }
}
exports.CandyShopTrade = CandyShopTrade;
