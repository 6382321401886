"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCandyShop = void 0;
const anchor_1 = require("@project-serum/anchor");
const vendor_1 = require("../../../../vendor");
async function updateCandyShop(params) {
    const { wallet, treasuryMint, sellerFeeBasisPoint, requiresSignOff, canChangeSalePrice, split, auctionHouse, auctionHouseAuthority, authorityBump, program } = params;
    const isNative = (0, vendor_1.treasuryMintIsNative)(treasuryMint);
    const [candyShop] = await (0, vendor_1.getCandyShop)(wallet.publicKey, treasuryMint, program.programId);
    const treasuryWithdrawalDestination = isNative
        ? auctionHouseAuthority
        : (await (0, vendor_1.getAtaForMint)(treasuryMint, auctionHouseAuthority))[0];
    const ix = await program.methods
        .updateCandyShopInfo(sellerFeeBasisPoint, requiresSignOff, canChangeSalePrice, split, authorityBump)
        .accounts({
        treasuryMint,
        wallet: wallet.publicKey,
        candyShopCreator: wallet.publicKey,
        authority: auctionHouseAuthority,
        feeWithdrawalDestination: wallet.publicKey,
        treasuryWithdrawalDestination,
        treasuryWithdrawalDestinationOwner: auctionHouseAuthority,
        auctionHouse,
        candyShop,
        ahProgram: vendor_1.AUCTION_HOUSE_PROGRAM_ID
    })
        .instruction();
    const transaction = new anchor_1.web3.Transaction();
    transaction.add(ix);
    const txId = await (0, vendor_1.sendTx)(wallet, transaction, program);
    console.log('updateCandyShopIx', txId);
    console.log('Auction house updated!');
    return txId;
}
exports.updateCandyShop = updateCandyShop;
