"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commitNft = void 0;
const web3_js_1 = require("@solana/web3.js");
const spl_token_1 = require("@solana/spl-token");
const vendor_1 = require("../../../../vendor");
const commitNft = async (params) => {
    const { nftOwner, candyShop, vaultAccount, nftOwnerTokenAccount, masterMint, price, startTime, salesPeriod, whitelistTime, program, candyShopProgram, whitelistMint } = params;
    await (0, vendor_1.checkCanCommit)(candyShop, nftOwner.publicKey, masterMint, candyShopProgram);
    const remainingAccounts = [];
    if (whitelistMint) {
        const vaultWlTokenAccount = await (0, spl_token_1.getAssociatedTokenAddress)(whitelistMint, vaultAccount, true);
        remainingAccounts.push({
            pubkey: whitelistMint,
            isWritable: true,
            isSigner: false
        });
        remainingAccounts.push({
            pubkey: vaultWlTokenAccount,
            isWritable: true,
            isSigner: false
        });
    }
    const [[vaultTokenAccount], [masterEditionMetadata], [masterEdition]] = await Promise.all([
        (0, vendor_1.getAtaForMint)(masterMint, vaultAccount),
        (0, vendor_1.getMetadataAccount)(masterMint),
        (0, vendor_1.getMasterEditionAccount)(masterMint)
    ]);
    const transaction = new web3_js_1.Transaction();
    const ix = await program.methods
        .shopCommitNft(price, startTime, salesPeriod, whitelistTime ? whitelistTime : null)
        .accounts({
        commitNftCtx: {
            masterEditionTokenAccountAuthority: nftOwner.publicKey,
            masterEditionTokenAccount: nftOwnerTokenAccount,
            vaultAccount,
            vaultTokenAccount,
            masterEditionMetadata: masterEditionMetadata,
            masterEditionAccount: masterEdition,
            masterEditionMint: masterMint,
            systemProgram: web3_js_1.SystemProgram.programId,
            tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
            associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: web3_js_1.SYSVAR_RENT_PUBKEY
        },
        candyShop
    })
        .remainingAccounts(remainingAccounts)
        .instruction();
    transaction.add(ix);
    return await (0, vendor_1.sendTx)(nftOwner, transaction, program);
};
exports.commitNft = commitNft;
