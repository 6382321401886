"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancelOrder = void 0;
const anchor = __importStar(require("@project-serum/anchor"));
const vendor_1 = require("../../../../vendor");
async function cancelOrder(params) {
    const { wallet, tokenAccount, tokenAccountMint, treasuryMint, authority, auctionHouse, feeAccount, tradeState, candyShop, price, amount, program } = params;
    const [sellTradeState, sellTradeStateBump] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, wallet.publicKey, tokenAccount, treasuryMint, tokenAccountMint, amount, price);
    await (0, vendor_1.checkNftAvailability)(program.provider.connection, tokenAccount, sellTradeState, sellTradeStateBump, amount.toNumber());
    const transaction = new anchor.web3.Transaction();
    const ix = await program.methods
        .cancelWithProxy(price, amount)
        .accounts({
        wallet: wallet.publicKey,
        tokenAccount,
        tokenMint: tokenAccountMint,
        authority,
        auctionHouse,
        auctionHouseFeeAccount: feeAccount,
        tradeState,
        candyShop,
        ahProgram: vendor_1.AUCTION_HOUSE_PROGRAM_ID
    })
        .instruction();
    transaction.add(ix);
    const txHash = await (0, vendor_1.sendTx)(wallet, transaction, program);
    console.log('sell order cancelled');
    return txHash;
}
exports.cancelOrder = cancelOrder;
