"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buyAndExecuteSale = void 0;
const anchor = __importStar(require("@project-serum/anchor"));
const anchor_1 = require("@project-serum/anchor");
const vendor_1 = require("../../../../vendor");
const validationUtils_1 = require("../../../../vendor/utils/validationUtils");
async function buyAndExecuteSale(params) {
    const { wallet, counterParty, tokenAccount, tokenAccountMint, treasuryMint, auctionHouseTreasury, metadata, authority, authorityBump, auctionHouse, feeAccount, candyShop, price, amount, program } = params;
    if (counterParty.toString() === wallet.publicKey.toString()) {
        throw new vendor_1.CandyShopError(vendor_1.CandyShopErrorType.BuyerOwnsListing);
    }
    const [buyerEscrow, buyerEscrowBump] = await (0, vendor_1.getAuctionHouseEscrow)(auctionHouse, wallet.publicKey);
    const [buyTradeState, buyTradeStateBump] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, wallet.publicKey, tokenAccount, treasuryMint, tokenAccountMint, amount, price);
    const isNative = (0, vendor_1.treasuryMintIsNative)(treasuryMint);
    const [sellTradeState, sellTradeStateBump] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, counterParty, tokenAccount, treasuryMint, tokenAccountMint, amount, price);
    const [freeTradeState, freeTradeStateBump] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, counterParty, tokenAccount, treasuryMint, tokenAccountMint, amount, new anchor.BN(0));
    const [programAsSigner, programAsSignerBump] = await (0, vendor_1.getAuctionHouseProgramAsSigner)();
    const transaction = new anchor_1.web3.Transaction();
    const paymentAccount = isNative ? wallet.publicKey : (await (0, vendor_1.getAtaForMint)(treasuryMint, wallet.publicKey))[0];
    await (0, validationUtils_1.checkPaymentAccountBalance)(program.provider.connection, paymentAccount, isNative, price.toNumber());
    await (0, validationUtils_1.checkNftAvailability)(program.provider.connection, tokenAccount, sellTradeState, sellTradeStateBump, amount.toNumber());
    const ix = await program.methods
        .buyWithProxy(price, amount, buyTradeStateBump, buyerEscrowBump, authorityBump)
        .accounts({
        wallet: wallet.publicKey,
        paymentAccount,
        transferAuthority: wallet.publicKey,
        treasuryMint,
        tokenAccount,
        metadata,
        escrowPaymentAccount: buyerEscrow,
        authority,
        auctionHouse,
        auctionHouseFeeAccount: feeAccount,
        buyerTradeState: buyTradeState,
        candyShop,
        ahProgram: vendor_1.AUCTION_HOUSE_PROGRAM_ID
    })
        .instruction();
    const metadataObj = await program.provider.connection.getAccountInfo(metadata);
    if (!(metadataObj === null || metadataObj === void 0 ? void 0 : metadataObj.data)) {
        throw new Error(vendor_1.CandyShopErrorType.InvalidNFTMetadata);
    }
    const metadataDecoded = (0, vendor_1.parseMetadata)(Buffer.from(metadataObj.data));
    const remainingAccounts = [];
    const accountsRequireAta = [];
    if (metadataDecoded && metadataDecoded.data && metadataDecoded.data.creators) {
        for (let creator of metadataDecoded.data.creators) {
            const creatorPublicKey = new anchor.web3.PublicKey(creator.address);
            remainingAccounts.push({
                pubkey: creatorPublicKey,
                isWritable: true,
                isSigner: false
            });
            if (!isNative) {
                const ataAddress = (await (0, vendor_1.getAtaForMint)(treasuryMint, creatorPublicKey))[0];
                remainingAccounts.push({
                    pubkey: ataAddress,
                    isWritable: true,
                    isSigner: false
                });
                accountsRequireAta.push(creatorPublicKey);
            }
        }
    }
    const sellerPaymentReceiptAccount = isNative ? counterParty : (await (0, vendor_1.getAtaForMint)(treasuryMint, counterParty))[0];
    if (!isNative) {
        accountsRequireAta.push(counterParty);
    }
    const allAtaIxs = [];
    const treasuyMintAtaIxs = await (0, vendor_1.compileAtaCreationIxs)(wallet.publicKey, accountsRequireAta, treasuryMint, program);
    if (treasuyMintAtaIxs) {
        allAtaIxs.push(...treasuyMintAtaIxs);
    }
    const buyerReceiptTokenAccount = (await (0, vendor_1.getAtaForMint)(tokenAccountMint, wallet.publicKey))[0];
    // for SOL as treausy shop we dont need this, as the ix has enough budget to complete execution
    // but use for non-SOL as treasury shop to save the execution budget of executeSaleWithProxy
    if (!isNative) {
        const tokenMintAtaIxs = await (0, vendor_1.compileAtaCreationIxs)(wallet.publicKey, [wallet.publicKey], tokenAccountMint, program);
        if (tokenMintAtaIxs) {
            allAtaIxs.push(...tokenMintAtaIxs);
        }
        await (0, validationUtils_1.checkDelegateOnReceiptAccounts)(program.provider.connection, sellerPaymentReceiptAccount, buyerReceiptTokenAccount);
    }
    const ix2 = await program.methods
        .executeSaleWithProxy(price, amount, buyerEscrowBump, freeTradeStateBump, programAsSignerBump, authorityBump, true)
        .accounts({
        buyer: wallet.publicKey,
        seller: counterParty,
        tokenAccount,
        tokenMint: tokenAccountMint,
        metadata,
        treasuryMint,
        escrowPaymentAccount: buyerEscrow,
        sellerPaymentReceiptAccount,
        buyerReceiptTokenAccount,
        authority,
        auctionHouse,
        auctionHouseFeeAccount: feeAccount,
        auctionHouseTreasury,
        buyerTradeState: buyTradeState,
        sellerTradeState: sellTradeState,
        freeTradeState: freeTradeState,
        candyShop,
        ahProgram: vendor_1.AUCTION_HOUSE_PROGRAM_ID,
        programAsSigner: programAsSigner
    })
        .remainingAccounts(remainingAccounts)
        .instruction();
    transaction.add(ix);
    transaction.add(ix2);
    if (allAtaIxs.length > 0) {
        const ataCreationTx = new anchor_1.web3.Transaction();
        ataCreationTx.add(...allAtaIxs);
        const atasCreationTx = await (0, vendor_1.sendTx)(wallet, ataCreationTx, program);
        console.log('atasCreationTx', atasCreationTx);
    }
    const buyAndExecuteTx = await (0, vendor_1.sendTx)(wallet, transaction, program);
    console.log('buyAndExecuteTx', buyAndExecuteTx);
    console.log('sale executed');
    return buyAndExecuteTx;
}
exports.buyAndExecuteSale = buyAndExecuteSale;
