"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sellNft = void 0;
const anchor = __importStar(require("@project-serum/anchor"));
const spl_token_1 = require("@solana/spl-token");
const vendor_1 = require("../../../../vendor");
async function sellNft(params) {
    const { wallet, tokenAccount, tokenAccountMint, treasuryMint, metadata, authority, auctionHouse, feeAccount, candyShop, price, amount, program } = params;
    await (0, vendor_1.checkCreators)(treasuryMint, tokenAccountMint, program.provider.connection, vendor_1.TransactionType.Marketplace);
    const [tradeState, tradeStateBump] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, wallet.publicKey, tokenAccount, treasuryMint, tokenAccountMint, amount, price);
    const [freeTradeState, freeTradeStateBump] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, wallet.publicKey, tokenAccount, treasuryMint, tokenAccountMint, amount, new anchor.BN(0));
    // TODO: re-enable it when the indexer is back on track. For now disable it to allow users to sell same NFT > 1 time
    // await checkTradeStateExist(program.provider.connection, tradeState, tradeStateBump);
    const [programAsSigner, programAsSignerBump] = await (0, vendor_1.getAuctionHouseProgramAsSigner)();
    const transaction = new anchor.web3.Transaction();
    const isNative = (0, vendor_1.treasuryMintIsNative)(treasuryMint);
    if (!isNative) {
        const sellerPaymentReceiptAccount = (await (0, vendor_1.getAtaForMint)(treasuryMint, wallet.publicKey))[0];
        const sellerPaymentReceiptAccountInfo = (await (0, vendor_1.safeAwait)((0, spl_token_1.getAccount)(program.provider.connection, sellerPaymentReceiptAccount))).result;
        if (sellerPaymentReceiptAccountInfo && sellerPaymentReceiptAccountInfo.delegate) {
            transaction.add((0, spl_token_1.createRevokeInstruction)(sellerPaymentReceiptAccount, wallet.publicKey));
        }
    }
    const ix = await program.methods
        .sellWithProxy(price, amount, tradeStateBump, freeTradeStateBump, programAsSignerBump)
        .accounts({
        wallet: wallet.publicKey,
        tokenAccount,
        metadata,
        authority,
        auctionHouse,
        auctionHouseFeeAccount: feeAccount,
        sellerTradeState: tradeState,
        freeSellerTradeState: freeTradeState,
        candyShop,
        ahProgram: vendor_1.AUCTION_HOUSE_PROGRAM_ID,
        programAsSigner
    })
        .instruction();
    transaction.add(ix);
    const txHash = await (0, vendor_1.sendTx)(wallet, transaction, program);
    console.log('sell order placed');
    return txHash;
}
exports.sellNft = sellNft;
