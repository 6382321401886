"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchEvmChainNftsFromWallet = exports.fetchNftByMint = void 0;
async function fetchNftByMint(axiosInstance, mint) {
    console.log('CandyShop: fetching NFT by mint address=', mint);
    const url = `/nft/${mint}`;
    return await axiosInstance.get(url).then((response) => response.data.result);
}
exports.fetchNftByMint = fetchNftByMint;
async function fetchEvmChainNftsFromWallet(axiosInstance, walletAddress, query) {
    console.log(`CandyShop: fetchUserEthWalletNft by wallet address=${walletAddress} with query`, query);
    const url = `/nft/eth-wallet/${walletAddress}`;
    return await axiosInstance
        .get(url, {
        params: query
    })
        .then((response) => response.data);
}
exports.fetchEvmChainNftsFromWallet = fetchEvmChainNftsFromWallet;
