"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CandyShopError = exports.CandyShopErrorMsgMap = exports.CandyShopErrorType = void 0;
var CandyShopErrorType;
(function (CandyShopErrorType) {
    CandyShopErrorType["IncorrectProgramId"] = "IncorrectProgramId";
    CandyShopErrorType["TransactionFailed"] = "TransactionFailed";
    CandyShopErrorType["InsufficientBalance"] = "InsufficientBalance";
    CandyShopErrorType["NFTUnavailable"] = "NFTUnavailable";
    CandyShopErrorType["BuyerOwnsListing"] = "BuyerOwnsListing";
    CandyShopErrorType["InvalidNFTMetadata"] = "InvalidNFTMetadata";
    CandyShopErrorType["SellerATACannotHaveDelegate"] = "SellerATACannotHaveDelegate";
    CandyShopErrorType["BuyerATACannotHaveDelegate"] = "BuyerATACannotHaveDelegate";
    CandyShopErrorType["TradeStateExists"] = "TradeStateExists";
    CandyShopErrorType["NonShopOwner"] = "NonShopOwner";
    CandyShopErrorType["AuctionExists"] = "AuctionExists";
    CandyShopErrorType["AuctionDoesNotExist"] = "AuctionDoesNotExist";
    CandyShopErrorType["BidDoesNotExist"] = "BidDoesNotExist";
    CandyShopErrorType["InvalidAuctionCreationParams"] = "InvalidAuctionCreationParams";
    CandyShopErrorType["CannotCancel"] = "CannotCancel";
    CandyShopErrorType["BidTooHigh"] = "BidTooHigh";
    CandyShopErrorType["BidTooLow"] = "BidTooLow";
    CandyShopErrorType["NotWithinBidPeriod"] = "NotWithinBidPeriod";
    CandyShopErrorType["AuctionHasNoBids"] = "AuctionHasNoBids";
    CandyShopErrorType["CannotWithdraw"] = "CannotWithdraw";
    CandyShopErrorType["BuyNowUnavailable"] = "BuyNowUnavailable";
    CandyShopErrorType["AuctionNotOver"] = "AuctionNotOver";
    CandyShopErrorType["InsufficientFeeAccountBalance"] = "InsufficientFeeAccountBalance";
    CandyShopErrorType["TooManyCreators"] = "TooManyCreators";
    CandyShopErrorType["CandyShopDoesNotExist"] = "CandyShopDoesNotExist";
    CandyShopErrorType["InvalidTreasuryMint"] = "InvalidTreasuryMint";
    CandyShopErrorType["InvalidNftOwner"] = "InvalidNftOwner";
    CandyShopErrorType["IncorrectCandyShopType"] = "IncorrectCandyShopType";
    CandyShopErrorType["VaultDoesNotExist"] = "VaultDoesNotExist";
    CandyShopErrorType["NotWithinSalesPeriod"] = "NotWithinSalesPeriod";
    CandyShopErrorType["DropNotRedeemable"] = "DropNotRedeemable";
    CandyShopErrorType["ExceedDropMaxAllowedSupply"] = "ExceedDropMaxAllowedSupply";
    CandyShopErrorType["NotReachable"] = "NotReachable";
    CandyShopErrorType["FailToFetchOnchainAccount"] = "FailToFetchOnchainAccount";
    CandyShopErrorType["InvalidDropStartTime"] = "InvalidDropStartTime";
    CandyShopErrorType["InvalidDropWhitelistTime"] = "InvalidDropWhitelistTime";
    CandyShopErrorType["MissingExtensionSetting"] = "MissingExtensionSetting";
    CandyShopErrorType["InvalidExtensionSettings"] = "InvalidExtensionSettings";
    CandyShopErrorType["InvalidAuctionBiddingPeriod"] = "InvalidAuctionBiddingPeriod";
    CandyShopErrorType["NodeRequestFailed"] = "NodeRequestFailed";
})(CandyShopErrorType = exports.CandyShopErrorType || (exports.CandyShopErrorType = {}));
exports.CandyShopErrorMsgMap = {
    [CandyShopErrorType.IncorrectProgramId]: 'Must use v2 program address to make use of this functionality.',
    [CandyShopErrorType.TransactionFailed]: 'Transaction failed. Please try again later.',
    [CandyShopErrorType.InsufficientBalance]: 'Insufficient balance.',
    [CandyShopErrorType.NFTUnavailable]: 'The NFT is no longer for sale.',
    [CandyShopErrorType.BuyerOwnsListing]: 'Cannot buy your own listing. Please cancel instead.',
    [CandyShopErrorType.InvalidNFTMetadata]: 'Failed to get metadata account data.',
    [CandyShopErrorType.SellerATACannotHaveDelegate]: 'Seller payment receipt account cannot have a delegate set.',
    [CandyShopErrorType.BuyerATACannotHaveDelegate]: 'Buyer receipt token account cannot have a delegate set.',
    [CandyShopErrorType.TradeStateExists]: 'Sell Order already placed.',
    [CandyShopErrorType.NonShopOwner]: 'Only shop owner can create auction.',
    [CandyShopErrorType.AuctionExists]: 'Auction already exists.',
    [CandyShopErrorType.AuctionDoesNotExist]: 'Auction does not exist.',
    [CandyShopErrorType.BidDoesNotExist]: 'Bid does not exist.',
    [CandyShopErrorType.AuctionHasNoBids]: 'Auctions without bids cannot be settled.',
    [CandyShopErrorType.BuyNowUnavailable]: 'Buy now is unavailable on this auction.',
    [CandyShopErrorType.CannotCancel]: 'This auction cannot be cancelled at this time.',
    [CandyShopErrorType.BidTooHigh]: 'Bid price is higher than auction buy now price. Please call that instruction instead.',
    [CandyShopErrorType.BidTooLow]: 'Bid price is too low to beat current highest bid.',
    [CandyShopErrorType.NotWithinBidPeriod]: 'Attempted to place a bid outside of auction bid period.',
    [CandyShopErrorType.CannotWithdraw]: 'This bid is currently the highest for the auction, it cannot be withdrawn at this time.',
    [CandyShopErrorType.InvalidAuctionCreationParams]: 'One or more of the passed auction creation parameters are invalid.',
    [CandyShopErrorType.AuctionNotOver]: 'Attempted to settle and auction that is not over.',
    [CandyShopErrorType.InsufficientFeeAccountBalance]: 'Please contact shop owner to top up shop syrup balance. Min balance requirement 0.05 SOL.',
    [CandyShopErrorType.TooManyCreators]: 'Nft has too many listed creators.',
    [CandyShopErrorType.CandyShopDoesNotExist]: 'Candy Shop does not exist.',
    [CandyShopErrorType.InvalidTreasuryMint]: 'Treasury mint must be wrapped SOL to use edition drop program.',
    [CandyShopErrorType.InvalidNftOwner]: 'Caller must be Candy Shop creator.',
    [CandyShopErrorType.IncorrectCandyShopType]: 'Candy Shop is of the incorrect type (enterprise or regular).',
    [CandyShopErrorType.VaultDoesNotExist]: 'Vault account does not exist.',
    [CandyShopErrorType.NotWithinSalesPeriod]: 'Attempted to mint print outside of sales period.',
    [CandyShopErrorType.DropNotRedeemable]: 'Drop not redeemable',
    [CandyShopErrorType.ExceedDropMaxAllowedSupply]: 'Max Edition number only allowed up to 10000',
    [CandyShopErrorType.NotReachable]: 'Unknown error. Please contact CandyShop team for further info.',
    [CandyShopErrorType.FailToFetchOnchainAccount]: 'Failed to fetch onchain account',
    [CandyShopErrorType.InvalidDropStartTime]: 'Invalid Drop start time',
    [CandyShopErrorType.InvalidDropWhitelistTime]: 'Invalid Drop whitelist time',
    [CandyShopErrorType.InvalidExtensionSettings]: 'Extension period must not be greater than the extension increment.',
    [CandyShopErrorType.MissingExtensionSetting]: 'Both extension settings must be provided to use this functionality.',
    [CandyShopErrorType.InvalidAuctionBiddingPeriod]: 'Invalid auction bidding period.',
    [CandyShopErrorType.NodeRequestFailed]: 'Node request failed.'
};
class CandyShopError extends Error {
    constructor(type) {
        super(exports.CandyShopErrorMsgMap[type]);
        this.type = type;
        Object.setPrototypeOf(this, CandyShopError.prototype);
    }
}
exports.CandyShopError = CandyShopError;
