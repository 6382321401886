"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancelAuction = void 0;
const web3_js_1 = require("@solana/web3.js");
const vendor_1 = require("../../../../vendor");
const cancelAuction = async (params) => {
    const { seller, auction, candyShop, auctionBump, treasuryMint, nftMint, program } = params;
    await (0, vendor_1.checkCanCancel)(auction, program);
    const [[auctionEscrow], [tokenAccount], [authority]] = await Promise.all([
        (0, vendor_1.getAtaForMint)(nftMint, auction),
        (0, vendor_1.getAtaForMint)(nftMint, seller.publicKey),
        (0, vendor_1.getAuctionHouseAuthority)(seller.publicKey, treasuryMint, program.programId)
    ]);
    const transaction = new web3_js_1.Transaction();
    const ix = await program.methods
        .cancelAuction(auctionBump)
        .accounts({
        auction,
        auctionEscrow,
        wallet: seller.publicKey,
        tokenAccount,
        nftMint,
        authority,
        candyShop,
        clock: web3_js_1.SYSVAR_CLOCK_PUBKEY
    })
        .instruction();
    transaction.add(ix);
    const txId = await (0, vendor_1.sendTx)(seller, transaction, program);
    console.log('Auction cancelled with txId ==', txId);
    return txId;
};
exports.cancelAuction = cancelAuction;
