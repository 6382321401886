"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropStatus = exports.DropType = void 0;
var DropType;
(function (DropType) {
    DropType[DropType["Editioned"] = 0] = "Editioned";
})(DropType = exports.DropType || (exports.DropType = {}));
var DropStatus;
(function (DropStatus) {
    DropStatus[DropStatus["CREATED"] = 0] = "CREATED";
    DropStatus[DropStatus["WHITELIST_STARTED"] = 1] = "WHITELIST_STARTED";
    DropStatus[DropStatus["SALE_STARTED"] = 2] = "SALE_STARTED";
    DropStatus[DropStatus["SALE_COMPLETED"] = 3] = "SALE_COMPLETED";
})(DropStatus = exports.DropStatus || (exports.DropStatus = {}));
