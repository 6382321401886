"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EvmChainQuery = void 0;
var EvmChainQuery;
(function (EvmChainQuery) {
    EvmChainQuery["ETH"] = "eth";
    EvmChainQuery["GOERLI"] = "goerli";
    EvmChainQuery["POLYGON"] = "polygon";
    EvmChainQuery["ROPSTEN"] = "ropsten";
    EvmChainQuery["RINKEBY"] = "rinkeby";
    EvmChainQuery["KOVAN"] = "kovan";
    EvmChainQuery["BSC"] = "bsc";
    EvmChainQuery["BSC_TESTNET"] = "bsc_testnet";
    EvmChainQuery["AVALANCHE"] = "avalanche";
    EvmChainQuery["FUJI"] = "fuji";
    EvmChainQuery["FANTOM"] = "fantom";
    EvmChainQuery["CRONOS"] = "cronos";
    EvmChainQuery["CRONOS_TESTNET"] = "cronos_testnet";
    // polygon testnet
    EvmChainQuery["MUMBAI"] = "mumbai";
})(EvmChainQuery = exports.EvmChainQuery || (exports.EvmChainQuery = {}));
