"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CandyShop = void 0;
const BaseShop_1 = require("./shop/base/BaseShop");
const BaseShopModel_1 = require("./shop/base/BaseShopModel");
const anchor_1 = require("@project-serum/anchor");
const CandyShopDrop_1 = require("./CandyShopDrop");
const CandyShopInfoAPI_1 = require("./CandyShopInfoAPI");
const CandyShopModel_1 = require("./CandyShopModel");
const CandyShopTrade_1 = require("./CandyShopTrade");
const constants_1 = require("./factory/constants");
const program_1 = require("./factory/program");
const vendor_1 = require("./vendor");
const config_1 = require("./vendor/config");
const shipping_1 = require("./vendor/shipping");
const Logger = 'CandyShop';
const DEFAULT_CURRENCY_SYMBOL = 'SOL';
const DEFAULT_CURRENCY_DECIMALS = 9;
const DEFAULT_PRICE_DECIMALS = 3;
const DEFAULT_PRICE_DECIMALS_MIN = 0;
const DEFAULT_VOLUME_DECIMALS = 1;
const DEFAULT_VOLUME_DECIMALS_MIN = 0;
const DEFAULT_MAINNET_CONNECTION_URL = 'https://ssc-dao.genesysgo.net/';
/**
 * @class CandyShop
 */
class CandyShop extends BaseShop_1.BaseShop {
    /**
     * Instantiate a CandyShop object
     *
     * @constructor
     * @param {CandyShopConstructorParams} params
     */
    // Changed constructor params to object, can revert if it will cause too many issues
    constructor(params) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        super(params);
        const { candyShopCreatorAddress, candyShopProgramId, treasuryMint, env, settings, isEnterprise } = params;
        this.verifyProgramId(candyShopProgramId);
        if (isEnterprise && !candyShopProgramId.equals(constants_1.CANDY_SHOP_V2_PROGRAM_ID)) {
            throw new vendor_1.CandyShopError(vendor_1.CandyShopErrorType.IncorrectProgramId);
        }
        this._candyShopAddress = (0, vendor_1.getCandyShopSync)(candyShopCreatorAddress, treasuryMint, candyShopProgramId)[0];
        this._candyShopCreatorAddress = candyShopCreatorAddress;
        this._treasuryMint = treasuryMint;
        this._programId = candyShopProgramId;
        this._env = env !== null && env !== void 0 ? env : 'devnet';
        this._isEnterprise = isEnterprise ? true : false;
        this._version = (0, vendor_1.getCandyShopVersion)(candyShopProgramId);
        this._settings = {
            currencySymbol: (_a = settings === null || settings === void 0 ? void 0 : settings.currencySymbol) !== null && _a !== void 0 ? _a : DEFAULT_CURRENCY_SYMBOL,
            currencyDecimals: (_b = settings === null || settings === void 0 ? void 0 : settings.currencyDecimals) !== null && _b !== void 0 ? _b : DEFAULT_CURRENCY_DECIMALS,
            priceDecimals: (_c = settings === null || settings === void 0 ? void 0 : settings.priceDecimals) !== null && _c !== void 0 ? _c : DEFAULT_PRICE_DECIMALS,
            priceDecimalsMin: (_d = settings === null || settings === void 0 ? void 0 : settings.priceDecimalsMin) !== null && _d !== void 0 ? _d : DEFAULT_PRICE_DECIMALS_MIN,
            volumeDecimals: (_e = settings === null || settings === void 0 ? void 0 : settings.volumeDecimals) !== null && _e !== void 0 ? _e : DEFAULT_VOLUME_DECIMALS,
            volumeDecimalsMin: (_f = settings === null || settings === void 0 ? void 0 : settings.volumeDecimalsMin) !== null && _f !== void 0 ? _f : DEFAULT_VOLUME_DECIMALS_MIN,
            mainnetConnectionUrl: (_g = settings === null || settings === void 0 ? void 0 : settings.mainnetConnectionUrl) !== null && _g !== void 0 ? _g : DEFAULT_MAINNET_CONNECTION_URL,
            connectionConfig: settings === null || settings === void 0 ? void 0 : settings.connectionConfig,
            explorerLink: (_h = settings === null || settings === void 0 ? void 0 : settings.explorerLink) !== null && _h !== void 0 ? _h : CandyShopModel_1.ExplorerLinkBase.SolanaFM
        };
        this._baseUnitsPerCurrency = Math.pow(10, this._settings.currencyDecimals);
        console.log('CandyShop constructor: init CandyShop=', this);
        (0, config_1.configBaseUrl)(env);
    }
    get currencyDecimals() {
        return this._settings.currencyDecimals;
    }
    get settings() {
        return this._settings;
    }
    get blockchain() {
        return BaseShopModel_1.Blockchain.Solana;
    }
    get env() {
        return this._env;
    }
    get treasuryMint() {
        return this._treasuryMint.toString();
    }
    get connectedPublicKey() {
        var _a;
        return (_a = this._program) === null || _a === void 0 ? void 0 : _a.provider.wallet.publicKey;
    }
    get candyShopCreatorAddress() {
        return this._candyShopCreatorAddress.toString();
    }
    get candyShopAddress() {
        return this._candyShopAddress.toString();
    }
    get programId() {
        return this._programId.toString();
    }
    get isEnterprise() {
        return this._isEnterprise;
    }
    get version() {
        return this._version;
    }
    get baseUnitsPerCurrency() {
        return this._baseUnitsPerCurrency;
    }
    get currencySymbol() {
        return this._settings.currencySymbol;
    }
    get priceDecimals() {
        return this._settings.priceDecimals;
    }
    get priceDecimalsMin() {
        return this._settings.priceDecimalsMin;
    }
    get volumeDecimals() {
        return this._settings.volumeDecimals;
    }
    get volumeDecimalsMin() {
        return this._settings.volumeDecimalsMin;
    }
    get explorerLink() {
        return this._settings.explorerLink;
    }
    verifyProgramId(programId) {
        switch (programId.toString()) {
            case constants_1.CANDY_SHOP_PROGRAM_ID.toString():
                break;
            case constants_1.CANDY_SHOP_V2_PROGRAM_ID.toString():
                break;
            default:
                throw new vendor_1.CandyShopError(vendor_1.CandyShopErrorType.IncorrectProgramId);
        }
    }
    /**
     * Get JSON rpc connection
     */
    connection() {
        const options = anchor_1.Provider.defaultOptions();
        if (this._env === 'devnet') {
            return new anchor_1.web3.Connection(anchor_1.web3.clusterApiUrl('devnet'));
        }
        return new anchor_1.web3.Connection(this._settings.mainnetConnectionUrl, this._settings.connectionConfig || options.commitment);
    }
    /**
     * Gets anchor Program object for Candy Shop program
     *
     * @param {AnchorWallet | web3.Keypair} wallet Wallet or keypair of connected user
     */
    getStaticProgram(wallet) {
        if (this._program)
            return this._program;
        return (0, vendor_1.getProgram)(this.connection(), this._programId, wallet);
    }
    /**
     * Executes Candy Shop __UpdateCandyShop__ action
     *
     * @param {CandyShopUpdateParams} params required parameters for update action
     */
    async updateCandyShop(params) {
        const { wallet, sellerFeeBasisPoint, requiresSignOff, canChangeSalePrice, split } = params;
        const [auctionHouseAuthority, authorityBump] = await (0, vendor_1.getAuctionHouseAuthority)(this._candyShopCreatorAddress, this._treasuryMint, this._programId);
        const [auctionHouse] = await (0, vendor_1.getAuctionHouse)(auctionHouseAuthority, this._treasuryMint);
        console.log(`${Logger}: performing update, `, {
            auctionHouse: auctionHouse.toString(),
            sellerFeeBasisPoint: sellerFeeBasisPoint ? sellerFeeBasisPoint.toString() : null
        });
        const updateCandyShopParams = {
            wallet,
            treasuryMint: this._treasuryMint,
            sellerFeeBasisPoint,
            requiresSignOff,
            canChangeSalePrice,
            split,
            auctionHouse,
            auctionHouseAuthority,
            authorityBump,
            program: this.getStaticProgram(wallet)
        };
        const txHash = await (0, shipping_1.supply)(updateCandyShopParams, this._version, program_1.updateCandyShopV1, program_1.updateCandyShop);
        return txHash;
    }
    /**
     * Executes Candy Shop __Buy__ and __ExecuteSale__ actions
     *
     * @param {CandyShopBuyParams} params required parameters for buy action
     */
    async buy(params) {
        const { seller, tokenAccount, tokenMint, price, wallet } = params;
        console.log(`${Logger}: performing buy, `, {
            seller: seller.toString(),
            tokenAccount: tokenAccount.toString(),
            tokenMint: tokenMint.toString(),
            price
        });
        const txHash = await CandyShopTrade_1.CandyShopTrade.buy({
            connection: this.connection(),
            shopAddress: this._candyShopAddress,
            candyShopProgramId: this._programId,
            shopCreatorAddress: this._candyShopCreatorAddress,
            shopTreasuryMint: this._treasuryMint,
            isEnterprise: this._isEnterprise,
            wallet: wallet,
            tokenAccount: tokenAccount,
            tokenMint: tokenMint,
            seller: seller,
            price: price
        });
        return txHash;
    }
    /**
     * Executes Candy Shop __Sell__ action
     *
     * @param {CandyShopSellParams} params required parameters for sell action
     */
    async sell(params) {
        const { tokenAccount, tokenMint, price, wallet } = params;
        console.log(`${Logger}: Performing sell `, {
            tokenMint: tokenMint.toString(),
            tokenAccount: tokenAccount.toString(),
            price
        });
        const txHash = await CandyShopTrade_1.CandyShopTrade.sell({
            connection: this.connection(),
            tokenAccount: tokenAccount,
            tokenMint: tokenMint,
            price: price,
            wallet: wallet,
            shopAddress: this._candyShopAddress,
            candyShopProgramId: this._programId,
            shopTreasuryMint: this._treasuryMint,
            shopCreatorAddress: this._candyShopCreatorAddress
        });
        return txHash;
    }
    /**
     * Executes Candy Shop __Cancel__ action
     *
     * @param {CandyShopCancelParams} params required parameters for cancel action
     */
    async cancel(params) {
        const { tokenAccount, tokenMint, price, wallet } = params;
        console.log('CandyShop: performing cancel', {
            tokenAccount: tokenAccount.toString(),
            tokenMint: tokenMint.toString(),
            price
        });
        const txHash = await CandyShopTrade_1.CandyShopTrade.cancel({
            connection: this.connection(),
            tokenAccount: tokenAccount,
            tokenMint: tokenMint,
            price: price,
            wallet: wallet,
            shopAddress: this._candyShopAddress,
            candyShopProgramId: this._programId,
            shopTreasuryMint: this._treasuryMint,
            shopCreatorAddress: this._candyShopCreatorAddress
        });
        return txHash;
    }
    /**
     * Executes Candy Shop __CreateAuction__ action
     *
     * @param {CandyShopCreateAuctionParams} params required parameters for create auction action
     */
    async createAuction(params) {
        const { tokenAccount, tokenMint, startingBid, startTime, biddingPeriod, buyNowPrice, wallet, tickSize, extensionPeriod, extensionIncrement } = params;
        if (wallet.publicKey.toString() !== this.candyShopCreatorAddress.toString()) {
            throw new vendor_1.CandyShopError(vendor_1.CandyShopErrorType.NonShopOwner);
        }
        if (biddingPeriod.isZero()) {
            throw new vendor_1.CandyShopError(vendor_1.CandyShopErrorType.InvalidAuctionBiddingPeriod);
        }
        console.log(`${Logger}: Performing create auction`, {
            tokenMint: tokenMint.toString(),
            tokenAccount: tokenAccount.toString(),
            startingBid: startingBid.toString(),
            startTime: startTime.toString(),
            biddingPeriod: biddingPeriod.toString(),
            buyNowPrice
        });
        const program = await this.getStaticProgram(wallet);
        const [auction, auctionBump] = await (0, vendor_1.getAuction)(this._candyShopAddress, tokenMint, this._programId);
        const auctionAccount = await program.provider.connection.getAccountInfo(auction);
        if (auctionAccount === null || auctionAccount === void 0 ? void 0 : auctionAccount.data) {
            throw new Error(vendor_1.CandyShopErrorType.AuctionExists);
        }
        const [auctionHouseAuthority] = await (0, vendor_1.getAuctionHouseAuthority)(this._candyShopCreatorAddress, this._treasuryMint, this._programId);
        const createAuctionParams = {
            seller: wallet,
            auction,
            authority: auctionHouseAuthority,
            auctionBump,
            candyShop: this._candyShopAddress,
            treasuryMint: this._treasuryMint,
            nftMint: tokenMint,
            startingBid,
            startTime,
            biddingPeriod,
            tickSize,
            buyNowPrice,
            extensionPeriod,
            extensionIncrement,
            program
        };
        const txHash = await (0, shipping_1.supply)(createAuctionParams, this._version, program_1.createAuctionV1, program_1.createAuction);
        return txHash;
    }
    /**
     * Executes Candy Shop __CancelAuction__ action
     *
     * @param {CandyShopCancelAuctionParams} params required parameters for cancel auction action
     */
    async cancelAuction(params) {
        const { tokenAccount, tokenMint, wallet } = params;
        if (wallet.publicKey.toString() !== this.candyShopCreatorAddress.toString()) {
            throw new vendor_1.CandyShopError(vendor_1.CandyShopErrorType.NonShopOwner);
        }
        console.log(`${Logger}: Performing cancel auction `, {
            tokenMint: tokenMint.toString(),
            tokenAccount: tokenAccount.toString()
        });
        const program = await this.getStaticProgram(wallet);
        const [auction, auctionBump] = await (0, vendor_1.getAuction)(this._candyShopAddress, tokenMint, this._programId);
        const auctionAccount = await program.provider.connection.getAccountInfo(auction);
        if (!auctionAccount) {
            throw new Error(vendor_1.CandyShopErrorType.AuctionDoesNotExist);
        }
        const [auctionHouseAuthority] = await (0, vendor_1.getAuctionHouseAuthority)(this._candyShopCreatorAddress, this._treasuryMint, this._programId);
        const cancelAuctionParams = {
            seller: wallet,
            auction,
            authority: auctionHouseAuthority,
            auctionBump,
            candyShop: this._candyShopAddress,
            treasuryMint: this._treasuryMint,
            nftMint: tokenMint,
            program
        };
        const txHash = await (0, shipping_1.supply)(cancelAuctionParams, this._version, program_1.cancelAuctionV1, program_1.cancelAuction);
        return txHash;
    }
    /**
     * Executes Candy Shop __MakeBid__ action
     *
     * @param {BidAuctionParams} params required parameters for make bid action
     */
    async bidAuction(params) {
        const { tokenAccount, tokenMint, wallet, bidPrice } = params;
        console.log(`${Logger}: performing bid auction, `, {
            bidPrice: bidPrice.toString(),
            tokenAccount: tokenAccount.toString()
        });
        const program = await this.getStaticProgram(wallet);
        const [auction] = await (0, vendor_1.getAuction)(this._candyShopAddress, tokenMint, this._programId);
        const auctionAccount = await program.provider.connection.getAccountInfo(auction);
        if (!auctionAccount) {
            throw new Error(vendor_1.CandyShopErrorType.AuctionDoesNotExist);
        }
        const [auctionHouseAuthority] = await (0, vendor_1.getAuctionHouseAuthority)(this._candyShopCreatorAddress, this._treasuryMint, this._programId);
        const [auctionHouse] = await (0, vendor_1.getAuctionHouse)(auctionHouseAuthority, this._treasuryMint);
        const [feeAccount] = await (0, vendor_1.getAuctionHouseFeeAcct)(auctionHouse);
        const [metadata] = await (0, vendor_1.getMetadataAccount)(tokenMint);
        const bidParams = {
            auction,
            authority: auctionHouseAuthority,
            candyShop: this._candyShopAddress,
            buyer: wallet,
            treasuryMint: this._treasuryMint,
            nftMint: tokenMint,
            metadata,
            auctionHouse,
            feeAccount,
            bidPrice,
            program
        };
        const txHash = await (0, shipping_1.supply)(bidParams, this._version, program_1.bidAuctionV1, program_1.bidAuction);
        return txHash;
    }
    /**
     * Executes Candy Shop __WithdrawBid__ action
     *
     * @param {CandyShopWithdrawAuctionBidParams} params required parameters for withdraw bid action
     */
    async withdrawAuctionBid(params) {
        const { tokenAccount, tokenMint, wallet } = params;
        console.log(`${Logger}: Performing withdraw bid auction `, {
            tokenAccount: tokenAccount.toString()
        });
        const program = await this.getStaticProgram(wallet);
        const [auction] = await (0, vendor_1.getAuction)(this._candyShopAddress, tokenMint, this._programId);
        const auctionAccount = await program.provider.connection.getAccountInfo(auction);
        if (!auctionAccount) {
            throw new Error(vendor_1.CandyShopErrorType.AuctionDoesNotExist);
        }
        const [auctionHouseAuthority] = await (0, vendor_1.getAuctionHouseAuthority)(this._candyShopCreatorAddress, this._treasuryMint, this._programId);
        const [auctionHouse] = await (0, vendor_1.getAuctionHouse)(auctionHouseAuthority, this._treasuryMint);
        const [feeAccount] = await (0, vendor_1.getAuctionHouseFeeAcct)(auctionHouse);
        const [metadata] = await (0, vendor_1.getMetadataAccount)(tokenMint);
        const withdrawBidParams = {
            auction,
            authority: auctionHouseAuthority,
            candyShop: this._candyShopAddress,
            buyer: wallet,
            treasuryMint: this._treasuryMint,
            nftMint: tokenMint,
            metadata,
            auctionHouse,
            feeAccount,
            program
        };
        const txHash = await (0, shipping_1.supply)(withdrawBidParams, this._version, program_1.withdrawBidV1, program_1.withdrawBid);
        return txHash;
    }
    /**
     * Executes Candy Shop __BuyNow__ action
     *
     * @param {CandyShopBuyNowParams} params required parameters for buy now action
     */
    async buyNowAuction(params) {
        const { tokenAccount, tokenMint, wallet } = params;
        console.log(`${Logger}: performing buy now auction `, {
            tokenAccount: tokenAccount.toString()
        });
        const program = await this.getStaticProgram(wallet);
        const [auction, auctionBump] = await (0, vendor_1.getAuction)(this._candyShopAddress, tokenMint, this._programId);
        const [auctionHouseAuthority] = await (0, vendor_1.getAuctionHouseAuthority)(this._candyShopCreatorAddress, this._treasuryMint, this._programId);
        const [auctionHouse] = await (0, vendor_1.getAuctionHouse)(auctionHouseAuthority, this._treasuryMint);
        const [feeAccount] = await (0, vendor_1.getAuctionHouseFeeAcct)(auctionHouse);
        const [treasuryAccount] = await (0, vendor_1.getAuctionHouseTreasuryAcct)(auctionHouse);
        const [metadata] = await (0, vendor_1.getMetadataAccount)(tokenMint);
        const buyNowParams = {
            auction,
            auctionBump,
            authority: auctionHouseAuthority,
            candyShop: this._candyShopAddress,
            buyer: wallet,
            treasuryMint: this._treasuryMint,
            nftMint: tokenMint,
            metadata,
            auctionHouse,
            feeAccount,
            treasuryAccount,
            program,
            env: this._env
        };
        const txHash = await (0, shipping_1.supply)(buyNowParams, this._version, program_1.buyNowAuctionV1, program_1.buyNowAuction);
        return txHash;
    }
    /**
     * Executes Candy Shop __SettleAuction__ and __DistributeProceeds__ actions
     *
     * @param {CandyShopSettleAndDistributeParams} params required parameters for settle auction and distribute proceed actions
     */
    async settleAndDistributeAuctionProceeds(params) {
        const { tokenAccount, tokenMint, wallet } = params;
        console.log(`${Logger}: performing settle auction and distribute proceeds `, {
            tokenAccount: tokenAccount.toString()
        });
        const program = await this.getStaticProgram(wallet);
        const [auction, auctionBump] = await (0, vendor_1.getAuction)(this._candyShopAddress, tokenMint, this._programId);
        const [auctionHouseAuthority] = await (0, vendor_1.getAuctionHouseAuthority)(this._candyShopCreatorAddress, this._treasuryMint, this._programId);
        const [auctionHouse] = await (0, vendor_1.getAuctionHouse)(auctionHouseAuthority, this._treasuryMint);
        const [feeAccount] = await (0, vendor_1.getAuctionHouseFeeAcct)(auctionHouse);
        const [treasuryAccount] = await (0, vendor_1.getAuctionHouseTreasuryAcct)(auctionHouse);
        const [metadata] = await (0, vendor_1.getMetadataAccount)(tokenMint);
        const settleAndDistributeParams = {
            auction,
            auctionBump,
            authority: auctionHouseAuthority,
            candyShop: this._candyShopAddress,
            settler: wallet,
            treasuryMint: this._treasuryMint,
            nftMint: tokenMint,
            metadata,
            auctionHouse,
            feeAccount,
            treasuryAccount,
            program,
            env: this._env
        };
        const txHash = await (0, shipping_1.supply)(settleAndDistributeParams, this._version, program_1.settleAndDistributeProceedsV1, program_1.settleAndDistributeProceeds);
        return txHash;
    }
    /**
     * Executes Edition Drop __ShopCommitNft__ or __EnterpriseCommitNft__ action
     * ref: https://docs.metaplex.com/terminology#master-edition
     *
     * @param {CandyShopCommitNftParams} params required parameters for commit nft action
     */
    async commitMasterNft(params) {
        const { nftOwnerTokenAccount, masterMint, whitelistMint, nftOwner, price, startTime, salesPeriod, whitelistTime } = params;
        if (this._version !== CandyShopModel_1.CandyShopVersion.V2) {
            throw new vendor_1.CandyShopError(vendor_1.CandyShopErrorType.IncorrectProgramId);
        }
        console.log(`${Logger}: performing commit nft `, {
            nftMint: masterMint.toString()
        });
        const txHash = await CandyShopDrop_1.CandyShopDrop.commitNft({
            candyShop: this._candyShopAddress,
            nftOwnerTokenAccount,
            masterMint,
            whitelistMint,
            nftOwner,
            price,
            startTime,
            salesPeriod,
            whitelistTime,
            isEnterprise: this._isEnterprise,
            connection: this.connection(),
            candyShopProgram: this.getStaticProgram(nftOwner)
        });
        return txHash;
    }
    /**
     * Executes Edition Drop __ShopMintPrint__ or __EnterpriseMintPrint__ action
     *
     * @param {CandyShopMintPrintParams} params required parameters for mint print action
     */
    async mintNewPrint(params) {
        const { nftOwnerTokenAccount, masterMint, whitelistMint, editionBuyer } = params;
        if (this._version !== CandyShopModel_1.CandyShopVersion.V2) {
            throw new vendor_1.CandyShopError(vendor_1.CandyShopErrorType.IncorrectProgramId);
        }
        console.log(`${Logger}: performing mint print `, {
            masterNft: masterMint.toString(),
            nftOwner: nftOwnerTokenAccount.toString(),
            editionBuyer: editionBuyer.publicKey.toString(),
            whitelistMint: whitelistMint ? whitelistMint.toString() : undefined
        });
        const [auctionHouseAuthority] = await (0, vendor_1.getAuctionHouseAuthority)(this._candyShopCreatorAddress, this._treasuryMint, this._programId);
        const [auctionHouse] = await (0, vendor_1.getAuctionHouse)(auctionHouseAuthority, this._treasuryMint);
        const txHash = await CandyShopDrop_1.CandyShopDrop.mintPrint({
            candyShop: this._candyShopAddress,
            nftOwnerTokenAccount,
            masterMint,
            whitelistMint,
            editionBuyer,
            auctionHouse,
            isEnterprise: this._isEnterprise,
            connection: this.connection(),
            candyShopProgram: this.getStaticProgram(editionBuyer),
            treasuryMint: this._treasuryMint
        });
        return txHash;
    }
    /**
     * Executes Edition Drop __RedeemNft__ action
     *
     * @param {CandyShopRedeemParams} params required parameters for mint print action
     */
    async redeemDrop(params) {
        const { nftOwnerTokenAccount, masterMint, nftOwner } = params;
        if (this._version !== CandyShopModel_1.CandyShopVersion.V2) {
            throw new vendor_1.CandyShopError(vendor_1.CandyShopErrorType.IncorrectProgramId);
        }
        console.log(`${Logger}: performing redeem drop `, {
            masterNft: masterMint.toString()
        });
        const txHash = await CandyShopDrop_1.CandyShopDrop.redeemDrop({
            nftOwner,
            candyShop: this._candyShopAddress,
            nftOwnerTokenAccount,
            masterMint,
            isEnterprise: this._isEnterprise,
            connection: this.connection(),
            candyShopProgram: this.getStaticProgram(nftOwner)
        });
        return txHash;
    }
    /**
     * Fetch stats associated with this Candy Shop
     */
    stats() {
        return (0, CandyShopInfoAPI_1.fetchStatsByShopAddress)(this.candyShopAddress);
    }
    /**
     * Fetch transactions made through this Candy Shop
     *
     * * @param {number[]} identifiers optional list of identifiers to apply to query string
     */
    transactions(queryDto) {
        return (0, CandyShopInfoAPI_1.fetchTradeByShopAddress)(this.candyShopAddress, queryDto);
    }
    /**
     * Fetch information on the specified nft
     *
     * @param {string} mint base 58 encoded mint key string
     */
    nftInfo(mint) {
        return (0, CandyShopInfoAPI_1.fetchNFTByMintAddress)(mint);
    }
    /**
     * Fetch orders matching specified filters
     *
     * @param {OrdersFilterQuery} ordersFilterQuery filters to apply to search
     */
    orders(ordersFilterQuery) {
        return (0, CandyShopInfoAPI_1.fetchOrdersByShopAddress)(this.candyShopAddress, ordersFilterQuery);
    }
    /**
     * Fetch child edition order associated with master edition
     *
     * @param {OrdersEditionFilterQuery} ordersEditionFilterQuery filters to apply to search
     * @param {string} masterMint base 58 encoded mint key string
     */
    childEditionOrders(masterMint, ordersEditionFilterQuery) {
        return (0, CandyShopInfoAPI_1.fetchOrdersByShopAndMasterEditionMint)(this.candyShopAddress, masterMint, ordersEditionFilterQuery);
    }
    /**
     * Fetch active orders created by specified wallet address
     *
     * @param {string} walletAddress base 58 encoded public key string
     */
    activeOrdersByWalletAddress(walletAddress) {
        return (0, CandyShopInfoAPI_1.fetchOrdersByShopAndWalletAddress)(this.candyShopAddress, walletAddress);
    }
    /**
     * Fetch list of whitelisted NFTs for this Candy Shop
     */
    shopWlNfts() {
        return (0, CandyShopInfoAPI_1.fetchShopWhitelistNftByShopAddress)(this.candyShopAddress);
    }
    /**
     * Fetch active orders associated with specified mint address
     *
     * @param {string} mintAddress base 58 encoded mint key string
     */
    activeOrderByMintAddress(mintAddress) {
        return (0, CandyShopInfoAPI_1.fetchOrderByShopAndMintAddress)(this.candyShopAddress, mintAddress);
    }
    /**
     * Fetch the data for Candy Shop with this Shop's public key
     */
    fetchShopByShopId() {
        return (0, CandyShopInfoAPI_1.fetchShopByShopAddress)(this.candyShopAddress);
    }
}
exports.CandyShop = CandyShop;
