"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supply = exports.proceedToBuy = void 0;
/*  This file should not be exposed in src/index.ts */
const CandyShopModel_1 = require("../CandyShopModel");
const marketplace_1 = require("../factory/program/v2/marketplace");
/**
 * Get txHash from different executions for proceeding to buy
 *
 * @param {boolean} isEnterprise
 * @param {BuyAndExecuteSaleTransactionParams} params required params for buy/sell transaction
 * @return {string} txHash
 */
function proceedToBuy(isEnterprise, callParams) {
    const { params, version, v1Func, v2Func } = callParams;
    if (isEnterprise) {
        return (0, marketplace_1.insBuyAndExecuteSale)(params);
    }
    return supply(params, version, v1Func, v2Func);
}
exports.proceedToBuy = proceedToBuy;
/**
 * Get txHash by calling either v1 or v2 version of passed function based on candy shop version
 *
 * @param {any} params argument to the function to call
 * @param {CandyShopVersion} version version of the candy shop
 * @param {function} v1Func function to call if using v1 candy shop
 * @param {function} v2Func function to call if using v2 candy shop
 * @return {string} txHash
 */
// Please feel free to come up with better name :)
function supply(params, version, v1Func, v2Func) {
    if (version === CandyShopModel_1.CandyShopVersion.V1) {
        return v1Func(params);
    }
    else {
        return v2Func(params);
    }
}
exports.supply = supply;
