"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Blockchain = void 0;
/**
 * Blockchain
 */
var Blockchain;
(function (Blockchain) {
    Blockchain["Ethereum"] = "ethereum";
    Blockchain["Solana"] = "solana";
    Blockchain["Polygon"] = "polygon";
})(Blockchain = exports.Blockchain || (exports.Blockchain = {}));
