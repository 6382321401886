"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchTokenFiatMoneyPrice = exports.confirmPaymentIntents = exports.createPaymentIntents = exports.checkPaymentAvailability = void 0;
/**
 * @note This backend Payment API should not be exposed in sdk entry.
 */
const PaymentRouter = '/payment';
async function checkPaymentAvailability(axiosInstance, params) {
    const { shopId, tokenAccount } = params;
    const url = `${PaymentRouter}/available/shop/${shopId}/token/${tokenAccount}`;
    return axiosInstance.get(url).then((res) => res.data);
}
exports.checkPaymentAvailability = checkPaymentAvailability;
async function createPaymentIntents(axiosInstance, requestBody) {
    const url = `${PaymentRouter}/create`;
    return axiosInstance.post(url, requestBody).then((res) => res.data);
}
exports.createPaymentIntents = createPaymentIntents;
function confirmPaymentIntents(axiosInstance, requestBody) {
    const url = `${PaymentRouter}/confirm`;
    return axiosInstance.post(url, requestBody).then((res) => res.data);
}
exports.confirmPaymentIntents = confirmPaymentIntents;
async function fetchTokenFiatMoneyPrice(axiosInstance, params, quotePriceQuery) {
    const { shopId, tokenAccount } = params;
    const { quoteCurrencyType } = quotePriceQuery || {};
    const url = `${PaymentRouter}/price/shop/${shopId}/token/${tokenAccount}${quoteCurrencyType ? `?quoteCurrencyType=${quoteCurrencyType}` : ''}`;
    return axiosInstance.get(url).then((res) => res.data);
}
exports.fetchTokenFiatMoneyPrice = fetchTokenFiatMoneyPrice;
