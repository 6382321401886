"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BidStatus = void 0;
var BidStatus;
(function (BidStatus) {
    BidStatus[BidStatus["OPEN"] = 0] = "OPEN";
    BidStatus[BidStatus["WITHDRAWN"] = 1] = "WITHDRAWN";
    BidStatus[BidStatus["WON"] = 2] = "WON";
    BidStatus[BidStatus["LOST"] = 3] = "LOST";
})(BidStatus = exports.BidStatus || (exports.BidStatus = {}));
