"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withdrawBid = void 0;
const anchor = __importStar(require("@project-serum/anchor"));
const web3_js_1 = require("@solana/web3.js");
const vendor_1 = require("../../../../vendor");
const withdrawBid = async ({ auction, authority, candyShop, buyer, treasuryMint, nftMint, metadata, auctionHouse, feeAccount, program }) => {
    await (0, vendor_1.checkAHFeeAccountBalance)(feeAccount, program.provider.connection);
    const [bidWallet, bidWalletBump] = await (0, vendor_1.getBidWallet)(auction, buyer.publicKey, program.programId);
    const isNative = (0, vendor_1.treasuryMintIsNative)(treasuryMint);
    const [auctionEscrow] = await (0, vendor_1.getAtaForMint)(nftMint, auction);
    const [bid] = await (0, vendor_1.getBid)(auction, buyer.publicKey, program.programId);
    await (0, vendor_1.checkCanWithdraw)(auction, bid, program);
    const bidAccount = await program.account.bid.fetch(bid);
    console.log('bidAccount');
    console.log(bidAccount);
    const [[userTreasuryAta], [escrowPaymentAccount, escrowPaymentAccountBump], [bidTradeState, bidTradeStateBump]] = await Promise.all([
        (0, vendor_1.getAtaForMint)(treasuryMint, buyer.publicKey),
        (0, vendor_1.getAuctionHouseEscrow)(auctionHouse, bidWallet),
        (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, bidWallet, auctionEscrow, treasuryMint, nftMint, new anchor.BN(1), bidAccount.price)
    ]);
    const bidReceiptAccount = isNative ? bidWallet : (await (0, vendor_1.getAtaForMint)(treasuryMint, bidWallet))[0];
    const transaction = new web3_js_1.Transaction();
    const ix = await program.methods
        .withdrawBid(bidWalletBump, escrowPaymentAccountBump)
        .accounts({
        wallet: buyer.publicKey,
        auction,
        candyShop,
        bid,
        auctionBidWallet: bidWallet,
        userTreasuryAta,
        bidReceiptAccount,
        nftMint,
        treasuryMint,
        auctionEscrow,
        metadata,
        escrowPaymentAccount,
        authority,
        auctionHouse,
        auctionHouseFeeAccount: feeAccount,
        bidTradeState,
        ahProgram: vendor_1.AUCTION_HOUSE_PROGRAM_ID,
        clock: web3_js_1.SYSVAR_CLOCK_PUBKEY
    })
        .instruction();
    transaction.add(ix);
    const txId = await (0, vendor_1.sendTx)(buyer, transaction, program);
    console.log('Bid withdrawn with txId ==', txId);
    return txId;
};
exports.withdrawBid = withdrawBid;
