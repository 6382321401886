"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUserMasterNFTs = exports.fetchNftsFromWallet = void 0;
const spl_token_1 = require("@solana/spl-token");
const fetchMetadata_1 = require("./fetchMetadata");
const anchor_1 = require("@project-serum/anchor");
const idb_1 = require("../../idb");
const promiseUtils_1 = require("../utils/promiseUtils");
const parseData_1 = require("./parseData");
const constants_1 = require("../../factory/constants");
const Logger = 'CandyShopSDK/fetchNfts';
// The endpoint we're using has request limitation.
// To play safe, set upper bound is 40 batches per 1000 ms
// 1 batch at least needs to wait (1000 / 40) ms to continue.
const DEFAULT_BATCH_SIZE = 40;
const DEFAULT_INTERVAL_MS = 1000;
const PER_BATCH_UPPER_BOUND_MS = DEFAULT_INTERVAL_MS / DEFAULT_BATCH_SIZE;
/**
 * @param connection anchor.web3.Connection
 * @param walletAddress target wallet anchor.web3.PublicKey to fetch the NFTs
 * @param identifiers for differentiate the collection
 * @param fetchNFTBatchParam the param object to specify batchCallback and batchSize
 * @param cacheNFTParam the param object to specify cache options
 * @returns array of the SingleTokenInfo in Promise
 */
const fetchNftsFromWallet = async (connection, walletAddress, identifiers, fetchNFTBatchParam, cacheNFTParam) => {
    const validTokenAccounts = await getValidTokenAccounts(connection, walletAddress);
    const singleTokenInfoPromiseParams = validTokenAccounts.map((tokenAccountAddress) => {
        const param = {
            connection: connection,
            identifiers: identifiers,
            tokenAccountAddress: tokenAccountAddress
        };
        return param;
    });
    const nftTokens = await getUserNFTDataArray(walletAddress.toString(), singleTokenInfoPromiseParams, fetchNFTBatchParam, cacheNFTParam);
    return nftTokens;
};
exports.fetchNftsFromWallet = fetchNftsFromWallet;
const getUserNFTDataArray = async (walletAddress, singleTokenInfoParams, fetchNFTBatchParam, cacheNFTParam) => {
    var _a;
    let params = singleTokenInfoParams;
    let cachedTokens = [];
    if (cacheNFTParam === null || cacheNFTParam === void 0 ? void 0 : cacheNFTParam.enable) {
        cachedTokens = (_a = (await (0, idb_1.retrieveWalletNftFromIDB)(walletAddress.toString()))) !== null && _a !== void 0 ? _a : [];
    }
    else {
        // Delete NFT IDB if cacheNFT is disabled
        await (0, idb_1.deleteCandyShopIDB)();
    }
    console.log(`${Logger}: Cached tokens in wallet ${walletAddress} =`, cachedTokens);
    cachedTokens = await removeOutdatedNftFromIDB(walletAddress, singleTokenInfoParams, cachedTokens);
    params = await updateSingleTokenParams(singleTokenInfoParams, cachedTokens);
    console.log(`${Logger}: Parameters for fetching from chain =`, params);
    let nftTokens = await fetchNFTDataArrayInBatches(params, fetchMetadata_1.singleTokenInfoPromise, fetchNFTBatchParam === null || fetchNFTBatchParam === void 0 ? void 0 : fetchNFTBatchParam.batchCallback, fetchNFTBatchParam === null || fetchNFTBatchParam === void 0 ? void 0 : fetchNFTBatchParam.batchSize, cachedTokens);
    // Concat cached nft and fetched nft
    nftTokens = cachedTokens.concat(nftTokens);
    // Update store object if cacheNFT is enabled and fetched tokens has updated
    if ((cacheNFTParam === null || cacheNFTParam === void 0 ? void 0 : cacheNFTParam.enable) && cachedTokens.length !== nftTokens.length) {
        await (0, idb_1.storeWalletNftToIDB)(walletAddress, nftTokens);
        console.log(`${Logger}: Updated new token to cache, cached tokens =`, nftTokens);
    }
    return nftTokens;
};
const updateSingleTokenParams = async (singleTokenInfoParams, cachedTokens) => {
    // Compare stored tokens vs on-chain tokens by tokenAccount to get new added NFT token account
    for (const cachedToken of cachedTokens) {
        singleTokenInfoParams = singleTokenInfoParams.filter((param) => param.tokenAccountAddress !== cachedToken.tokenAccountAddress);
    }
    return singleTokenInfoParams;
};
const removeOutdatedNftFromIDB = async (walletAddress, singleTokenInfoParams, cachedTokens) => {
    // Remove nft that token amount is zero by comparing all singleTokenInfoParams
    let removal = [...cachedTokens];
    for (const singleTokenParam of singleTokenInfoParams) {
        removal = removal.filter((token) => (token.metadata && !(0, fetchMetadata_1.isValidWhitelistNft)(singleTokenParam.identifiers, token.metadata)) ||
            token.tokenAccountAddress !== singleTokenParam.tokenAccountAddress);
    }
    if (removal.length > 0) {
        cachedTokens = cachedTokens.filter((token) => !removal.includes(token));
        console.log(`${Logger}: After removed outdated token, cache tokens=`, cachedTokens);
        await (0, idb_1.storeWalletNftToIDB)(walletAddress, cachedTokens);
    }
    return cachedTokens;
};
const fetchNFTDataArrayInBatches = async (array, singleTokenInfoPromise, batchCallback, batchSize, cachedTokenInfo) => {
    const validBatchSize = getValidChunkSize(batchSize);
    const delayMs = validBatchSize * PER_BATCH_UPPER_BOUND_MS;
    console.log(`${Logger}: Executing ${array.length} promises in batches with size ${validBatchSize} per ${delayMs} ms.`);
    let aggregated = [];
    let batchNum = 1;
    let count = 0;
    // Return cached tokens in first batch if batchCallback is specified and cache is available
    if (batchCallback && cachedTokenInfo) {
        batchCallback(cachedTokenInfo);
    }
    while (count < array.length) {
        const batch = array.slice(count, count + validBatchSize);
        const promises = batch.map((param) => singleTokenInfoPromise(param));
        const tokenInfoBatch = await Promise.all(promises);
        console.log(`${Logger}: The batch ${batchNum} have been all resolved.`);
        const validTokenInfoBatch = tokenInfoBatch.filter((res) => res !== null);
        // Only provide the batch result when batchCallback is specified.
        if (batchCallback) {
            batchCallback(validTokenInfoBatch);
        }
        aggregated = aggregated.concat(validTokenInfoBatch);
        await (0, promiseUtils_1.sleepPromise)(delayMs);
        batchNum++;
        count += validBatchSize;
    }
    return aggregated;
};
const getValidTokenAccounts = async (connection, walletAddress) => {
    // Filter out invalid token which is not NFT.
    const tokenAccounts = await connection.getParsedTokenAccountsByOwner(walletAddress, { programId: spl_token_1.TOKEN_PROGRAM_ID });
    return tokenAccounts.value
        .filter((account) => {
        const tokenAmount = account.account.data.parsed.info.tokenAmount;
        const tokenAmountIsOne = Number(tokenAmount.amount) === 1;
        const tokenDecimalsIsZero = Number(tokenAmount.decimals) === 0;
        if (tokenAmountIsOne && tokenDecimalsIsZero)
            return true;
        return false;
    })
        .map((account) => account.pubkey.toString());
};
const getValidChunkSize = (chunkSize) => {
    if (!chunkSize || (chunkSize && chunkSize > DEFAULT_BATCH_SIZE)) {
        return DEFAULT_BATCH_SIZE;
    }
    return chunkSize;
};
const fetchUserMasterNFTs = async (userWalletPublicKey, connection) => {
    const [rawTokens, nftsInfo] = await Promise.all([
        (0, promiseUtils_1.safeAwait)(connection.getParsedTokenAccountsByOwner(userWalletPublicKey, { programId: spl_token_1.TOKEN_PROGRAM_ID })),
        (0, promiseUtils_1.safeAwait)((0, exports.fetchNftsFromWallet)(connection, userWalletPublicKey, undefined))
    ]);
    if (!rawTokens.result || !nftsInfo.result) {
        if (rawTokens.error) {
            console.log(`${Logger} connection.getParsedTokenAccountsByOwner failed, error=`, rawTokens.error);
        }
        if (nftsInfo.error) {
            console.log(`${Logger} fetchNftsFromWallet failed, error=`, nftsInfo.error);
        }
        return [];
    }
    const tokenInfoMap = new Map();
    for (const token of rawTokens.result.value) {
        if (Number(token.account.data.parsed.info.tokenAmount.amount) > 0) {
            const item = {
                account: token.account,
                tokenPubkey: token.pubkey.toString(),
                tokenMint: token.account.data.parsed.info.mint,
                amount: token.account.data.parsed.info.tokenAmount.amount
            };
            tokenInfoMap.set(item.tokenPubkey, item);
        }
    }
    const nftsInfoMap = new Map();
    for (const nft of nftsInfo.result) {
        nftsInfoMap.set(nft.tokenMintAddress, nft);
    }
    const nfts = await Promise.all(Array.from(tokenInfoMap.values()).map(async (e) => {
        var _a;
        const [newEditionPublicKey] = await anchor_1.web3.PublicKey.findProgramAddress([
            Buffer.from('metadata'),
            constants_1.TOKEN_METADATA_PROGRAM_ID.toBuffer(),
            new anchor_1.web3.PublicKey(e.tokenMint).toBuffer(),
            Buffer.from('edition')
        ], constants_1.TOKEN_METADATA_PROGRAM_ID);
        const newEditionAccountInfoResult = await (0, promiseUtils_1.safeAwait)(connection.getAccountInfo(newEditionPublicKey));
        if (newEditionAccountInfoResult.error) {
            console.log(`${Logger} getAccountInfo token=${e.tokenMint} failed, error=`, newEditionAccountInfoResult.error);
            return undefined;
        }
        if (!newEditionAccountInfoResult.result) {
            console.log(`${Logger} getAccountInfo token=${e.tokenMint} empty`);
            return undefined;
        }
        const masterEditionInfo = (0, parseData_1.parseMasterEditionV2)(newEditionAccountInfoResult.result.data);
        const editionInfo = (0, parseData_1.parseEdition)(newEditionAccountInfoResult.result.data);
        return {
            ...e,
            maxSupply: (_a = masterEditionInfo.maxSupply) === null || _a === void 0 ? void 0 : _a.toString(),
            supply: masterEditionInfo.supply.toString(),
            edition: editionInfo.edition.toString()
        };
    }));
    return nfts.reduce((result, nft) => {
        var _a, _b;
        if (nft && nft.tokenMint && Number(nft.maxSupply) > 1 && Number(nft.edition) === 0) {
            const info = nftsInfoMap.get(nft.tokenMint);
            result.push({
                ...nft,
                ...info,
                name: (_a = info === null || info === void 0 ? void 0 : info.metadata) === null || _a === void 0 ? void 0 : _a.data.name,
                symbol: (_b = info === null || info === void 0 ? void 0 : info.metadata) === null || _b === void 0 ? void 0 : _b.data.symbol
            });
        }
        return result;
    }, []);
};
exports.fetchUserMasterNFTs = fetchUserMasterNFTs;
