"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestExtraComputeIx = void 0;
const web3_js_1 = require("@solana/web3.js");
const requestExtraComputeIx = (amount) => {
    return web3_js_1.ComputeBudgetProgram.requestUnits({
        units: amount,
        additionalFee: 0
    });
};
exports.requestExtraComputeIx = requestExtraComputeIx;
