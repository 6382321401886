"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FETCH_LIST_LIMIT = exports.SPL_AUCTION_CREATORS_LIMIT = exports.NATIVE_AUCTION_CREATORS_LIMIT = exports.SPL_MARKETPLACE_CREATORS_LIMIT = exports.NATIVE_MARKETPLACE_CREATORS_LIMIT = exports.FEE_ACCOUNT_MIN_BAL = exports.EDITION_MARKER_BIT_SIZE = exports.EDITION_DROP = exports.ORDER = exports.AUTHORITY = exports.CANDY_STORE = exports.TREASURY = exports.FEE_PAYER = exports.AUCTION_HOUSE = exports.AUCTION = exports.WALLET = exports.BID = exports.TOKEN_METADATA_PROGRAM_ID = exports.EDITION_DROP_PROGRAM_ID = exports.COMPUTE_BUDGET_PROGRAM_ID = exports.CANDY_SHOP_V2_PROGRAM_ID = exports.CANDY_SHOP_PROGRAM_ID = exports.LIQNFT_TREASURY_ACCOUNT = exports.AUCTION_HOUSE_PROGRAM_ID = exports.WRAPPED_SOL_MINT = void 0;
const anchor_1 = require("@project-serum/anchor");
exports.WRAPPED_SOL_MINT = new anchor_1.web3.PublicKey('So11111111111111111111111111111111111111112');
exports.AUCTION_HOUSE_PROGRAM_ID = new anchor_1.web3.PublicKey('hausS13jsjafwWwGqZTUQRmWyvyxn9EQpqMwV1PBBmk');
exports.LIQNFT_TREASURY_ACCOUNT = new anchor_1.web3.PublicKey('91jjF761KfDyXE6uFRe3zyRESPwQtewo8hxHc3yFQaRF');
exports.CANDY_SHOP_PROGRAM_ID = new anchor_1.web3.PublicKey('csa8JpYfKSZajP7JzxnJipUL3qagub1z29hLvp578iN');
exports.CANDY_SHOP_V2_PROGRAM_ID = new anchor_1.web3.PublicKey('csbMUULiQfGjT8ezT16EoEBaiarS6VWRevTw1JMydrS');
exports.COMPUTE_BUDGET_PROGRAM_ID = new anchor_1.web3.PublicKey('ComputeBudget111111111111111111111111111111');
exports.EDITION_DROP_PROGRAM_ID = new anchor_1.web3.PublicKey('eddn8tqKsfj3oezin66nmoK5PYrq28dEWMXZYxoQUus');
exports.TOKEN_METADATA_PROGRAM_ID = new anchor_1.web3.PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');
exports.BID = 'bid';
exports.WALLET = 'wallet';
exports.AUCTION = 'auction';
exports.AUCTION_HOUSE = 'auction_house';
exports.FEE_PAYER = 'fee_payer';
exports.TREASURY = 'treasury';
exports.CANDY_STORE = 'candy_shop';
exports.AUTHORITY = 'authority';
exports.ORDER = 'order';
exports.EDITION_DROP = 'drop';
exports.EDITION_MARKER_BIT_SIZE = 248;
exports.FEE_ACCOUNT_MIN_BAL = 0.05 * anchor_1.web3.LAMPORTS_PER_SOL;
exports.NATIVE_MARKETPLACE_CREATORS_LIMIT = 5;
exports.SPL_MARKETPLACE_CREATORS_LIMIT = 3;
exports.NATIVE_AUCTION_CREATORS_LIMIT = 5;
exports.SPL_AUCTION_CREATORS_LIMIT = 2;
exports.FETCH_LIST_LIMIT = 10;
