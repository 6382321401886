"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.safeAwait = exports.sleepPromise = void 0;
function sleepPromise(ms) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, ms);
    });
}
exports.sleepPromise = sleepPromise;
/* Helper buddy for removing async/await try/catch litter */
const safeAwait = (promise, finallyCallback) => {
    return promise
        .then((data) => {
        return { result: data, error: undefined };
    })
        .catch((error) => {
        return { result: undefined, error: error };
    })
        .finally(() => {
        if (finallyCallback && typeof finallyCallback === 'function') {
            finallyCallback();
        }
    });
};
exports.safeAwait = safeAwait;
