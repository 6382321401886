"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkExtensionParams = exports.checkDropCommittable = exports.checkRedeemable = exports.checkEditionMintPeriod = exports.checkCanCommitEnterprise = exports.checkCanCommit = exports.checkAHFeeAccountBalance = exports.checkCreators = exports.checkSettleParams = exports.checkBuyNowAvailable = exports.checkCanWithdraw = exports.checkBidParams = exports.checkIfBidExists = exports.checkBidPeriod = exports.checkCanCancel = exports.checkCreationParams = exports.checkDelegateOnReceiptAccounts = exports.checkPaymentAccountBalance = exports.checkNftAvailability = exports.checkTradeStateExist = exports.TransactionType = void 0;
const anchor_1 = require("@project-serum/anchor");
const spl_token_1 = require("@solana/spl-token");
const _1 = require(".");
const constants_1 = require("../../factory/constants");
const error_1 = require("../error");
const parseData_1 = require("../token/parseData");
const programUtils_1 = require("./programUtils");
const promiseUtils_1 = require("./promiseUtils");
var TransactionType;
(function (TransactionType) {
    TransactionType["Marketplace"] = "Marketplace";
    TransactionType["Auction"] = "Auction";
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));
const checkTradeStateExist = async (connection, sellTradeState, sellTradeStateBump) => {
    const sellTradeStateInfo = await connection.getAccountInfo(sellTradeState);
    if ((sellTradeStateInfo === null || sellTradeStateInfo === void 0 ? void 0 : sellTradeStateInfo.data[0]) === sellTradeStateBump) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.TradeStateExists);
    }
};
exports.checkTradeStateExist = checkTradeStateExist;
const checkNftAvailability = async (connection, tokenAccount, sellTradeState, sellTradeStateBump, amount) => {
    const [programAsSigner] = await (0, programUtils_1.getAuctionHouseProgramAsSigner)();
    const tokenAccountInfo = await (0, spl_token_1.getAccount)(connection, tokenAccount);
    const sellTradeStateInfo = await connection.getAccountInfo(sellTradeState);
    if (!tokenAccountInfo.delegate ||
        tokenAccountInfo.delegate.toString() !== programAsSigner.toString() ||
        Number(tokenAccountInfo.amount) < amount ||
        (sellTradeStateInfo === null || sellTradeStateInfo === void 0 ? void 0 : sellTradeStateInfo.data[0]) != sellTradeStateBump) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.NFTUnavailable);
    }
};
exports.checkNftAvailability = checkNftAvailability;
const checkPaymentAccountBalance = async (connection, paymentAccount, isNative, price) => {
    // If isNative = true then payment account = calling user's pubkey
    // i.e. connection.getAccountInfo(paymentAccount) will not return null
    let paymentAccountBalance;
    if (isNative) {
        const info = await connection.getAccountInfo(paymentAccount);
        paymentAccountBalance = info === null || info === void 0 ? void 0 : info.lamports;
    }
    else {
        const accountBalance = await (0, promiseUtils_1.safeAwait)(connection.getTokenAccountBalance(paymentAccount));
        if (accountBalance.error) {
            console.log('checkPaymentAccountBalance: getTokenAccountBalance error= ', accountBalance.error);
            paymentAccountBalance = undefined;
        }
        else {
            paymentAccountBalance = new anchor_1.BN(accountBalance.result.value.amount).toNumber();
        }
    }
    if (!paymentAccountBalance || paymentAccountBalance < price) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.InsufficientBalance);
    }
};
exports.checkPaymentAccountBalance = checkPaymentAccountBalance;
const checkDelegateOnReceiptAccounts = async (connection, sellerPaymentReceiptAccount, buyerReceiptTokenAccount) => {
    const sellerPaymentReceiptAccountInfoRes = await (0, promiseUtils_1.safeAwait)((0, spl_token_1.getAccount)(connection, sellerPaymentReceiptAccount));
    const buyerReceiptTokenAccountInfoRes = await (0, promiseUtils_1.safeAwait)((0, spl_token_1.getAccount)(connection, buyerReceiptTokenAccount));
    const sellerPaymentReceiptAccountInfo = sellerPaymentReceiptAccountInfoRes.result;
    const buyerReceiptTokenAccountInfo = buyerReceiptTokenAccountInfoRes.result;
    if (sellerPaymentReceiptAccountInfo && sellerPaymentReceiptAccountInfo.delegate) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.SellerATACannotHaveDelegate);
    }
    if (buyerReceiptTokenAccountInfo && buyerReceiptTokenAccountInfo.delegate) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.BuyerATACannotHaveDelegate);
    }
};
exports.checkDelegateOnReceiptAccounts = checkDelegateOnReceiptAccounts;
const checkCreationParams = (startTime, startingBid, buyNowPrice, tickSize) => {
    if (tickSize.lten(0) ||
        startTime.ltn(Date.now() / 1000 - 60) ||
        (buyNowPrice && buyNowPrice.lt(startingBid.add(tickSize)))) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.InvalidAuctionCreationParams);
    }
};
exports.checkCreationParams = checkCreationParams;
const checkCanCancel = async (auction, program) => {
    const currentTime = new anchor_1.BN(Date.now() / 1000);
    const auctionData = await (0, programUtils_1.getAuctionData)(auction, program);
    const auctionEndTime = auctionData.startTime.add(auctionData.biddingPeriod);
    if ((currentTime.gt(auctionEndTime) && auctionData.highestBid != null) ||
        (currentTime.gt(auctionData.startTime) && currentTime.lt(auctionEndTime))) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.CannotCancel);
    }
};
exports.checkCanCancel = checkCanCancel;
const checkBidPeriod = async (auction, program) => {
    const currentTime = new anchor_1.BN(Date.now() / 1000);
    const auctionData = await (0, programUtils_1.getAuctionData)(auction, program);
    const auctionEndTime = auctionData.startTime.add(auctionData.biddingPeriod);
    if (currentTime.lt(auctionData.startTime) || currentTime.gt(auctionEndTime)) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.NotWithinBidPeriod);
    }
};
exports.checkBidPeriod = checkBidPeriod;
const checkIfBidExists = async (bid, connection) => {
    const bidAccount = await connection.getAccountInfo(bid);
    if (bidAccount !== null)
        return true;
    return false;
};
exports.checkIfBidExists = checkIfBidExists;
const checkBidParams = async (auction, bidPrice, program) => {
    const auctionData = await (0, programUtils_1.getAuctionData)(auction, program);
    await (0, exports.checkBidPeriod)(auction, program);
    if (auctionData.buyNowPrice && bidPrice.gt(auctionData.buyNowPrice)) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.BidTooHigh);
    }
    if ((auctionData.highestBid && bidPrice.lt(auctionData.highestBid.price.add(auctionData.tickSize))) ||
        (!auctionData.highestBid && bidPrice.lt(auctionData.startingBid))) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.BidTooLow);
    }
};
exports.checkBidParams = checkBidParams;
const checkCanWithdraw = async (auction, bid, program) => {
    const auctionData = await (0, programUtils_1.getAuctionData)(auction, program);
    if (auctionData.highestBid && auctionData.highestBid.key.equals(bid)) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.CannotWithdraw);
    }
};
exports.checkCanWithdraw = checkCanWithdraw;
const checkBuyNowAvailable = async (auction, program) => {
    const auctionData = await (0, programUtils_1.getAuctionData)(auction, program);
    if (!auctionData.buyNowPrice) {
        throw new Error(error_1.CandyShopErrorType.BuyNowUnavailable);
    }
    return auctionData.buyNowPrice;
};
exports.checkBuyNowAvailable = checkBuyNowAvailable;
const checkSettleParams = async (auction, program) => {
    const auctionData = await (0, programUtils_1.getAuctionData)(auction, program);
    const currentTime = new anchor_1.BN(Date.now() / 1000);
    const auctionEndTime = auctionData.startTime.add(auctionData.biddingPeriod);
    if (currentTime.lt(auctionEndTime)) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.AuctionNotOver);
    }
    if (!auctionData.highestBid) {
        throw new Error(error_1.CandyShopErrorType.AuctionHasNoBids);
    }
};
exports.checkSettleParams = checkSettleParams;
const checkCreators = async (treasuryMint, nftMint, connection, transactionType) => {
    const isNative = (0, programUtils_1.treasuryMintIsNative)(treasuryMint);
    const [nftMetadata] = await (0, programUtils_1.getMetadataAccount)(nftMint);
    const creators = await (0, programUtils_1.getNftCreators)(nftMetadata, connection);
    const creatorsLimit = getCreatorLimit(isNative, transactionType);
    if (creators.length > creatorsLimit) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.TooManyCreators);
    }
};
exports.checkCreators = checkCreators;
const checkAHFeeAccountBalance = async (feeAccount, connection) => {
    const feeAccountInfo = await connection.getAccountInfo(feeAccount);
    if (!feeAccountInfo || feeAccountInfo.lamports < constants_1.FEE_ACCOUNT_MIN_BAL) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.InsufficientFeeAccountBalance);
    }
};
exports.checkAHFeeAccountBalance = checkAHFeeAccountBalance;
const getCreatorLimit = (isNative, transactionType) => {
    switch (transactionType) {
        case TransactionType.Marketplace:
            return isNative ? constants_1.NATIVE_MARKETPLACE_CREATORS_LIMIT : constants_1.SPL_MARKETPLACE_CREATORS_LIMIT;
        case TransactionType.Auction:
            return isNative ? constants_1.NATIVE_AUCTION_CREATORS_LIMIT : constants_1.SPL_AUCTION_CREATORS_LIMIT;
        default:
            throw new error_1.CandyShopError(error_1.CandyShopErrorType.NotReachable);
    }
};
const checkCanCommit = async (candyShop, nftOwner, masterMint, program) => {
    await (0, exports.checkDropCommittable)(masterMint, program);
    const candyShopData = await (0, _1.getCandyShopData)(candyShop, false, program);
    if (!candyShopData.creator.equals(nftOwner)) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.InvalidNftOwner);
    }
    if (!Object.keys(candyShopData.key).includes('candyShopV1')) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.IncorrectCandyShopType);
    }
};
exports.checkCanCommit = checkCanCommit;
const checkCanCommitEnterprise = async (candyShop, masterMint, program) => {
    await (0, exports.checkDropCommittable)(masterMint, program);
    const candyShopData = await (0, _1.getCandyShopData)(candyShop, true, program);
    if (!candyShopData.treasuryMint.equals(constants_1.WRAPPED_SOL_MINT)) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.InvalidTreasuryMint);
    }
    if (!Object.keys(candyShopData.key).includes('enterpriseCandyShopV1')) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.IncorrectCandyShopType);
    }
};
exports.checkCanCommitEnterprise = checkCanCommitEnterprise;
const checkEditionMintPeriod = async (vaultAccount, program) => {
    var _a;
    const vaultData = await (0, _1.getEditionVaultData)(vaultAccount, program);
    const currentTime = new anchor_1.BN(Date.now() / 1000);
    const saleEndTime = vaultData.startingTime.add(vaultData.salesPeriod);
    if (currentTime.lt((_a = vaultData.whitelistTime) !== null && _a !== void 0 ? _a : vaultData.startingTime) || currentTime.gte(saleEndTime)) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.NotWithinSalesPeriod);
    }
    return vaultData;
};
exports.checkEditionMintPeriod = checkEditionMintPeriod;
const checkRedeemable = async (vaultAccount, program) => {
    const vaultData = await (0, _1.getEditionVaultData)(vaultAccount, program);
    const currentTime = new anchor_1.BN(Date.now() / 1000);
    const salesEndTime = vaultData.startingTime.add(vaultData.salesPeriod);
    if (vaultData.currentSupply.gt(new anchor_1.BN(0)) ||
        (vaultData.whitelistTime !== null && currentTime.gte(vaultData.whitelistTime) && currentTime.lt(salesEndTime)) ||
        (vaultData.startingTime !== null && currentTime.gte(vaultData.startingTime) && currentTime.lt(salesEndTime))) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.DropNotRedeemable);
    }
};
exports.checkRedeemable = checkRedeemable;
const checkDropCommittable = async (masterMint, program) => {
    const maxSupportedEdition = new anchor_1.BN(10000);
    const [nftEditionPublicKey] = await anchor_1.web3.PublicKey.findProgramAddress([Buffer.from('metadata'), constants_1.TOKEN_METADATA_PROGRAM_ID.toBuffer(), masterMint.toBuffer(), Buffer.from('edition')], constants_1.TOKEN_METADATA_PROGRAM_ID);
    const nftEditionAccountInfo = await (0, promiseUtils_1.safeAwait)(program.provider.connection.getAccountInfo(nftEditionPublicKey));
    if (nftEditionAccountInfo.error) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.FailToFetchOnchainAccount);
    }
    const maxSupply = nftEditionAccountInfo.result
        ? await (0, parseData_1.parseMasterEditionV2)(nftEditionAccountInfo.result.data).maxSupply
        : undefined;
    if (maxSupply && maxSupply.gt(maxSupportedEdition)) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.ExceedDropMaxAllowedSupply);
    }
};
exports.checkDropCommittable = checkDropCommittable;
const checkExtensionParams = (extensionPeriod, extensionIncrement) => {
    if ((extensionPeriod && !extensionIncrement) || (!extensionPeriod && extensionIncrement)) {
        throw new error_1.CandyShopError(error_1.CandyShopErrorType.MissingExtensionSetting);
    }
    if (extensionIncrement && extensionPeriod) {
        if (extensionPeriod.gt(extensionIncrement)) {
            throw new error_1.CandyShopError(error_1.CandyShopErrorType.InvalidExtensionSettings);
        }
    }
};
exports.checkExtensionParams = checkExtensionParams;
