"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CANDY_SHOP_V2_PROGRAM_ID = exports.AUCTION_HOUSE_PROGRAM_ID = exports.WRAPPED_SOL_MINT = void 0;
/* Public vendor methods to be exposed in src/index.ts */
__exportStar(require("./token"), exports);
__exportStar(require("./utils"), exports);
__exportStar(require("./error"), exports);
__exportStar(require("./config"), exports);
/* Control the factory exposure */
var constants_1 = require("../factory/constants");
Object.defineProperty(exports, "WRAPPED_SOL_MINT", { enumerable: true, get: function () { return constants_1.WRAPPED_SOL_MINT; } });
Object.defineProperty(exports, "AUCTION_HOUSE_PROGRAM_ID", { enumerable: true, get: function () { return constants_1.AUCTION_HOUSE_PROGRAM_ID; } });
Object.defineProperty(exports, "CANDY_SHOP_V2_PROGRAM_ID", { enumerable: true, get: function () { return constants_1.CANDY_SHOP_V2_PROGRAM_ID; } });
