"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mintPrint = void 0;
const web3_js_1 = require("@solana/web3.js");
const spl_token_1 = require("@solana/spl-token");
const vendor_1 = require("../../../../vendor");
const constants_1 = require("../../../constants");
const mintPrint = async (newTokenInstruction, params) => {
    const { editionBuyer, candyShop, vaultAccount, auctionHouse, nftOwnerTokenAccount, masterMint, newEditionTokenAccount, newEditionMint, editionNumber, program, whitelistMint, treasuryMint } = params;
    await (0, vendor_1.checkEditionMintPeriod)(vaultAccount, program);
    const remainingAccounts = [];
    if (!treasuryMint.equals(constants_1.WRAPPED_SOL_MINT)) {
        const buyerSplTokenAccount = await (0, spl_token_1.getAssociatedTokenAddress)(treasuryMint, editionBuyer.publicKey, true);
        remainingAccounts.push({
            pubkey: buyerSplTokenAccount,
            isWritable: true,
            isSigner: false
        });
    }
    if (whitelistMint) {
        const [userWlTokenAccount, vaultWlTokenAccount] = await Promise.all([
            (0, spl_token_1.getAssociatedTokenAddress)(whitelistMint, editionBuyer.publicKey, true),
            (0, spl_token_1.getAssociatedTokenAddress)(whitelistMint, vaultAccount, true)
        ]);
        remainingAccounts.push({
            pubkey: whitelistMint,
            isWritable: true,
            isSigner: false
        });
        remainingAccounts.push({
            pubkey: userWlTokenAccount,
            isWritable: true,
            isSigner: false
        });
        remainingAccounts.push({
            pubkey: vaultWlTokenAccount,
            isWritable: true,
            isSigner: false
        });
    }
    const [[vaultTokenAccount], [shopTreasuryAddress], [masterEditionMetadata], [masterEdition], [newEditionMetadata], [newEdition], [newEditionMark]] = await Promise.all([
        (0, vendor_1.getAtaForMint)(masterMint, vaultAccount),
        (0, vendor_1.getAuctionHouseTreasuryAcct)(auctionHouse),
        (0, vendor_1.getMetadataAccount)(masterMint),
        (0, vendor_1.getMasterEditionAccount)(masterMint),
        (0, vendor_1.getMetadataAccount)(newEditionMint.publicKey),
        (0, vendor_1.getMasterEditionAccount)(newEditionMint.publicKey),
        (0, vendor_1.getEditionMarkAccount)(masterMint, editionNumber.toNumber())
    ]);
    const updateAuthority = await (0, vendor_1.parseNftUpdateAuthority)(masterEditionMetadata, program.provider.connection);
    const transaction = new web3_js_1.Transaction();
    transaction.add(...newTokenInstruction);
    const ix = await program.methods
        .shopMintPrint(editionNumber)
        .accounts({
        mintPrintCtx: {
            newEditionBuyer: editionBuyer.publicKey,
            vaultAccount,
            vaultTokenAccount,
            masterEditionMetadata,
            masterEditionUpdateAuthority: updateAuthority,
            masterEditionAccount: masterEdition,
            masterEditionMint: masterMint,
            masterEditionTokenAccount: nftOwnerTokenAccount,
            newEditionMetadata,
            newEditionAccount: newEdition,
            newEditionMarker: newEditionMark,
            newEditionMint: newEditionMint.publicKey,
            newEditionTokenAccount,
            shopTreasuryAddress,
            tokenMetadataProgram: constants_1.TOKEN_METADATA_PROGRAM_ID,
            systemProgram: web3_js_1.SystemProgram.programId,
            tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
            rent: web3_js_1.SYSVAR_RENT_PUBKEY
        },
        candyShop
    })
        .remainingAccounts(remainingAccounts)
        .instruction();
    transaction.add(ix);
    return await (0, vendor_1.sendTx)(editionBuyer, transaction, program, [newEditionMint]);
};
exports.mintPrint = mintPrint;
