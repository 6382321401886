"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buyNowAuction = void 0;
const anchor = __importStar(require("@project-serum/anchor"));
const web3_js_1 = require("@solana/web3.js");
const vendor_1 = require("../../../../vendor");
const requestExtraComputeIx_1 = require("./requestExtraComputeIx");
const validationUtils_1 = require("../../../../vendor/utils/validationUtils");
const buyNowAuction = async ({ candyShop, auction, auctionBump, authority, buyer, treasuryMint, nftMint, metadata, auctionHouse, feeAccount, treasuryAccount, program, env }) => {
    await (0, validationUtils_1.checkAHFeeAccountBalance)(feeAccount, program.provider.connection);
    const isNative = (0, vendor_1.treasuryMintIsNative)(treasuryMint);
    const auctionData = await (0, vendor_1.getAuctionData)(auction, program);
    await (0, validationUtils_1.checkBidPeriod)(auction, program);
    const buyNowPrice = await (0, validationUtils_1.checkBuyNowAvailable)(auction, program);
    const seller = auctionData.seller;
    const [auctionEscrow] = await (0, vendor_1.getAtaForMint)(nftMint, auction);
    const [buyerReceiptTokenAccount] = await (0, vendor_1.getAtaForMint)(nftMint, buyer.publicKey);
    const sellerPaymentReceiptAccount = isNative ? seller : (await (0, vendor_1.getAtaForMint)(treasuryMint, seller))[0];
    const paymentAccount = isNative ? buyer.publicKey : (await (0, vendor_1.getAtaForMint)(treasuryMint, buyer.publicKey))[0];
    const auctionPaymentReceiptAccount = isNative ? auction : (await (0, vendor_1.getAtaForMint)(treasuryMint, auction))[0];
    const [escrowPaymentAccount, escrowPaymentAccountBump] = await (0, vendor_1.getAuctionHouseEscrow)(auctionHouse, buyer.publicKey);
    const [auctionTradeState, auctionTradeStateBump] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, auction, auctionEscrow, treasuryMint, nftMint, new anchor.BN(1), buyNowPrice);
    const [freeAuctionTradeState, freeAuctionTradeStateBump] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, auction, auctionEscrow, treasuryMint, nftMint, new anchor.BN(1), new anchor.BN(0));
    const [buyerTradeState, buyerTradeStateBump] = await (0, vendor_1.getAuctionHouseTradeState)(auctionHouse, buyer.publicKey, auctionEscrow, treasuryMint, nftMint, new anchor.BN(1), buyNowPrice);
    const [programAsSigner, programAsSignerBump] = await (0, vendor_1.getAuctionHouseProgramAsSigner)();
    const remainingAccounts = await (0, vendor_1.getRemainingAccountsForExecuteSaleIx)(metadata, program.provider.connection, treasuryMint, isNative);
    const transaction = new web3_js_1.Transaction();
    transaction.add((0, requestExtraComputeIx_1.requestExtraComputeIx)(1400000));
    const ix = await program.methods
        .buyNow(auctionBump, auctionTradeStateBump, buyerTradeStateBump, escrowPaymentAccountBump, freeAuctionTradeStateBump, programAsSignerBump)
        .accounts({
        wallet: buyer.publicKey,
        seller,
        sellerPaymentReceiptAccount,
        auction,
        candyShop,
        paymentAccount,
        transferAuthority: buyer.publicKey,
        nftMint,
        treasuryMint,
        auctionEscrow,
        metadata,
        escrowPaymentAccount,
        auctionPaymentReceiptAccount,
        buyerReceiptTokenAccount,
        authority,
        auctionHouse,
        auctionHouseFeeAccount: feeAccount,
        auctionHouseTreasury: treasuryAccount,
        buyerTradeState,
        auctionTradeState,
        freeAuctionTradeState,
        ahProgram: vendor_1.AUCTION_HOUSE_PROGRAM_ID,
        programAsSigner,
        clock: web3_js_1.SYSVAR_CLOCK_PUBKEY
    })
        .remainingAccounts(remainingAccounts)
        .instruction();
    transaction.add(ix);
    const txId = await (0, vendor_1.sendTx)(buyer, transaction, program);
    console.log('Buy Now called with txId ==', txId);
    return txId;
};
exports.buyNowAuction = buyNowAuction;
