"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentErrorName = void 0;
var PaymentErrorName;
(function (PaymentErrorName) {
    PaymentErrorName["InvalidBuyerWallet"] = "InvalidWallet";
    PaymentErrorName["NotAllowedShop"] = "NotAllowedShop";
    PaymentErrorName["NotAllowedNft"] = "NotAllowedNft";
    PaymentErrorName["InvalidToken"] = "InvalidToken";
    PaymentErrorName["UnavailableToken"] = "UnavailableToken";
    PaymentErrorName["InsufficientPurchaseBalance"] = "InsufficientPurchaseBalance";
    // TODO: define error to more granular level
    PaymentErrorName["PurchaseNftError"] = "PurchaseNftError";
    PaymentErrorName["TransferNftError"] = "TransferNftError";
    PaymentErrorName["UnknownNftTransactionError"] = "UnknownNftTransactionError";
    PaymentErrorName["DataServiceError"] = "DataServiceError";
    PaymentErrorName["ExchangeRateError"] = "ExchangeRateError";
    PaymentErrorName["OutdatedPrice"] = "OutdatedPrice";
    PaymentErrorName["UnsupportedCurrency"] = "UnsupportedCurrency";
    PaymentErrorName["BelowMinPurchasePrice"] = "BelowMinPurchasePrice";
})(PaymentErrorName = exports.PaymentErrorName || (exports.PaymentErrorName = {}));
